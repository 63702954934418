import { useQuery } from '@apollo/client';
import { graphql } from '@app/graphql/types';

const QUERY = graphql(`
  query StoreFrontBranch($branchID: ID!) {
    storefront_branch(id: $branchID) {
      name
      website
      timezone
      streetAddress
      addressCountry
      addressLocality
      addressRegion
      postalCode
      companyId
    }
  }
`);

export default function useStoreFrontBranch(branchID: string) {
  return useQuery(QUERY, {
    variables: {
      branchID: branchID,
    },
  });
}

import { ApolloError } from '@apollo/client';
import AlertError from './alert-error.tsx';

const ApplicationErrorView = (props: { className?: string; error: ApolloError | undefined }) => {
  if (!props.error) {
    return <div />;
  }

  return <AlertError className={props.className} title="Error" message={props.error.message} />;
};

export default ApplicationErrorView;

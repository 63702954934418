import { pickedProductID, useProductPicker } from '@app/page/online-order/product-picker/logic/use-product-picker.ts';
import { MenuItemFragment } from '@app/graphql/types/graphql.ts';
import { getFragmentData } from '@app/graphql/types';
import {
  MenuProductConfigurationFragment,
  MenuProductConfigurationValueFragment,
  MenuProductFragment,
} from '@app/page/online-order/model/menu-product-fragment.ts';
import { calculatePickedProduct } from '@app/page/online-order/product-picker/logic/calculate-picked-product.ts';
import CurrencyView from '@app/components/price/currency-view.tsx';
import { calculateTotal } from '@app/page/online-order/product-picker/logic/calculate-total.tsx';
import CartButton from './cart-button';
import { useTranslate } from '@tolgee/react';

const Cart = (props: { menu: MenuItemFragment }) => {
  const pickedProducts = useProductPicker((state) => state.products);

  const products = getFragmentData(MenuProductFragment, props.menu.menuProducts);

  const total = calculateTotal(pickedProducts, props.menu);
  const { t } = useTranslate();

  return (
    <div className="mt-4 flex flex-col divide-y rounded-lg border px-4 *:py-4 first:*:pt-4 last:*:pb-4">
      {pickedProducts.map((pickedProduct) => {
        const product = products.find((product) => product.id === pickedProduct.productID);

        if (!product) {
          return <div key={pickedProductID(pickedProduct)}>Unknown product {pickedProduct.productID}</div>;
        } else {
          const configurations = getFragmentData(MenuProductConfigurationFragment, product.configurations);
          const productPrice = calculatePickedProduct(
            pickedProduct.configurations.map((e) => e.valueID),
            product,
            1
          );

          const totalProductPrice = calculatePickedProduct(
            pickedProduct.configurations.map((e) => e.valueID),
            product,
            pickedProduct.quantity
          );

          const configurationValueText = pickedProduct.configurations
            .map((pickedConfig) => {
              const configuration = configurations.find((c) => c.id === pickedConfig.configurationID);

              if (!configuration) {
                return `Unknown configuration ${pickedConfig.configurationID}`;
              } else {
                const values = getFragmentData(MenuProductConfigurationValueFragment, configuration.values);
                const pickedValue = values.find((v) => v.id === pickedConfig.valueID);

                if (!pickedValue) {
                  return `Unknown value ${pickedConfig.valueID}`;
                } else {
                  return pickedValue.name;
                }
              }
            })
            .join(', ');

          return (
            <div key={pickedProductID(pickedProduct)} className="flex items-center gap-3">
              <div className="flex flex-col gap-1.5">
                {/*Title*/}
                <div className="font-semibold">
                  {product.code}. {product.title}
                </div>
                {/*Description*/}
                <div className="flex flex-wrap space-x-1 text-gray-500">{configurationValueText}</div>
                <div className="flex items-center gap-1">
                  <CartButton product={product} pickedProduct={pickedProduct} />
                </div>
              </div>

              <div className="flex-grow" />

              {/*Price*/}
              <div className="flex-shrink-0">
                <div className="flex flex-col items-end">
                  <CurrencyView price={totalProductPrice} />
                  <div className="text-sm text-gray-500">
                    <span>{pickedProduct.quantity} x </span>
                    <CurrencyView price={productPrice} />
                  </div>
                </div>
              </div>
            </div>
          );
        }
      })}

      <div className="flex justify-end">
        <span>{t('online-order.cart-bar.cart.total', 'Total')} :</span>
        <div className="flex-grow" />
        <CurrencyView className="ml-1 font-semibold" price={total} />
      </div>
    </div>
  );
};

export default Cart;

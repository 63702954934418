import { AllergicType } from '@app/graphql/types/graphql.ts';
import { ReactSVG } from 'react-svg';

export default function AllergicDisplay(props: { allergics: AllergicType[] }) {
  return (
    <div className={'flex gap-1'}>
      {props.allergics.map((item, index) => (
        <div className="h-5 w-5" key={index}>
          {convertAllergicToIcon(item)}
        </div>
      ))}
    </div>
  );
}

function convertAllergicToIcon(allergic: AllergicType) {
  switch (allergic) {
    case AllergicType.Celery:
      return (
        <ReactSVG
          src="https://inhouse-storage.fra1.cdn.digitaloceanspaces.com/static/celery-icon.svg"
          beforeInjection={(svg) => {
            svg.classList.add('w-5', 'h-5');
          }}
        />
      );
    case AllergicType.CerealsContainingGluten:
      return (
        <ReactSVG
          src="https://inhouse-storage.fra1.cdn.digitaloceanspaces.com/static/gluten-icon.svg"
          beforeInjection={(svg) => {
            svg.classList.add('w-5', 'h-5');
          }}
        />
      );
    case AllergicType.Crustaceans:
      return (
        <ReactSVG
          src="https://inhouse-storage.fra1.cdn.digitaloceanspaces.com/static/crustacean-icon.svg"
          beforeInjection={(svg) => {
            svg.classList.add('w-5', 'h-5');
          }}
        />
      );
    case AllergicType.Eggs:
      return (
        <ReactSVG
          src="https://inhouse-storage.fra1.cdn.digitaloceanspaces.com/static/egg-icon.svg"
          beforeInjection={(svg) => {
            svg.classList.add('w-5', 'h-5');
          }}
        />
      );
    case AllergicType.Fish:
      return (
        <ReactSVG
          src="https://inhouse-storage.fra1.cdn.digitaloceanspaces.com/static/fish-icon.svg"
          beforeInjection={(svg) => {
            svg.classList.add('w-5', 'h-5');
          }}
        />
      );
    case AllergicType.Lupin:
      return (
        <ReactSVG
          src="https://inhouse-storage.fra1.cdn.digitaloceanspaces.com/static/lupin-icon.svg"
          beforeInjection={(svg) => {
            svg.classList.add('w-5', 'h-5');
          }}
        />
      );
    case AllergicType.Milk:
      return (
        <ReactSVG
          src="https://inhouse-storage.fra1.cdn.digitaloceanspaces.com/static/milk-icon.svg"
          className={'h-10 w-10'}
          beforeInjection={(svg) => {
            svg.classList.add('w-5', 'h-5');
          }}
        />
      );
    case AllergicType.Molluscs:
      return (
        <ReactSVG
          src="https://inhouse-storage.fra1.cdn.digitaloceanspaces.com/static/molluscs-icon.svg"
          beforeInjection={(svg) => {
            svg.classList.add('w-5', 'h-5');
          }}
        />
      );
    case AllergicType.Mustard:
      return (
        <ReactSVG
          src="https://inhouse-storage.fra1.cdn.digitaloceanspaces.com/static/mustard-icon.svg"
          beforeInjection={(svg) => {
            svg.classList.add('w-5', 'h-5');
          }}
        />
      );
    case AllergicType.Peanuts:
      return (
        <ReactSVG
          src="https://inhouse-storage.fra1.cdn.digitaloceanspaces.com/static/peanut-icon.svg"
          beforeInjection={(svg) => {
            svg.classList.add('w-5', 'h-5');
          }}
        />
      );
    case AllergicType.Sesame:
      return (
        <ReactSVG
          src="https://inhouse-storage.fra1.cdn.digitaloceanspaces.com/static/sesame-icon.svg"
          beforeInjection={(svg) => {
            svg.classList.add('w-5', 'h-5');
          }}
        />
      );
    case AllergicType.Soybeans:
      return (
        <ReactSVG
          src="https://inhouse-storage.fra1.cdn.digitaloceanspaces.com/static/soybean-icon.svg"
          beforeInjection={(svg) => {
            svg.querySelector('path')?.setAttribute('fill', '#990000');
            svg.classList.add('w-5', 'h-5');
          }}
        />
      );
    case AllergicType.SulfurDioxideAndSulfites:
      return (
        <ReactSVG
          src="https://inhouse-storage.fra1.cdn.digitaloceanspaces.com/static/sulfur-dioxide-icon.svg"
          beforeInjection={(svg) => {
            svg.classList.add('w-5', 'h-5');
          }}
        />
      );
    case AllergicType.TreeNuts:
      return (
        <ReactSVG
          src="https://inhouse-storage.fra1.cdn.digitaloceanspaces.com/static/tree-nut-icon.svg"
          beforeInjection={(svg) => {
            svg.classList.add('w-5', 'h-5');
          }}
        />
      );
  }
}

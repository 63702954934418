import { useMutation } from '@apollo/client';
import { graphql } from '@app/graphql/types';

export const QUERY = graphql(`
   mutation CreateOnlineOrder($branchID: ID!, $input: OnlineOrderCreateInput!) {
        storefront_createOnlineOrder(branchID: $branchID, input: $input) {
            id
        }
   }
`);

export function useCreateOnlineOrder() {
  return useMutation(QUERY);
}

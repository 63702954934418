import { useMutation } from '@apollo/client';
import { graphql } from '@app/graphql/types';

export const QUERY = graphql(`
    mutation ModifyOnlineOrderCart($onlineOrderProductID: ID!, $amount: Int!) {
        storefront_changeProductAmount(onlineOrderProductID: $onlineOrderProductID, amount: $amount)
    }
`);

export function useOnlineOrderModifyCart() {
  return useMutation(QUERY, {refetchQueries: ['OnlineOrderDetailPage'],});
}

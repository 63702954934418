// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { AddressAutofill } from '@mapbox/search-js-react';
import { ChangeEvent } from 'react';
import { useTranslate } from '@tolgee/react';

const AutofillAddressInput = (props: {
  values: {
    streetAddress: string;
    streetAddress2: string;
    addressLocality: string;
    addressRegion: string;
    postalCode: string;
    // addressCountry: string;
  };
  streetAddress: (value: string) => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  error?: Record<string, string>;
}) => {
  const accessToken = import.meta.env.VITE_MAP_BOX as string;
  const { t } = useTranslate();
  // TODO: pass country and language from backend
  return (
    <AddressAutofill
      accessToken={accessToken}
      options={{
        country: 'de',
        language: 'de',
      }}
    >
      <div className="flex flex-col space-y-4">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div>
            <label htmlFor="streetAddress" className="block text-sm font-medium leading-6 text-gray-900">
              {t('online-order.fill-contact-page.form-input.delivery.address-1.label', 'Address line 1')}
            </label>

            <input
              type="text"
              name="streetAddress"
              autoComplete="address-line1"
              className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder={t(
                'online-order.fill-contact-page.form-input.delivery.address-1.placeholder',
                'Address line 1'
              )}
              value={props.values.streetAddress}
              onChange={(e) => {
                props.streetAddress(e.target.value);
              }}
            />

            <ErrorLabel error={props.error?.streetAddress} />
          </div>

          <div>
            <label htmlFor="streetAddress2" className="block text-sm font-medium leading-6 text-gray-900">
              {t('online-order.fill-contact-page.form-input.delivery.address-2.label', 'Address line 2')}
            </label>

            <input
              type="text"
              name="streetAddress2"
              autoComplete="address-line2"
              className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder={t(
                'online-order.fill-contact-page.form-input.delivery.address-2.placeholder',
                'Address line 2'
              )}
              value={props.values.streetAddress2}
              onChange={props.onChange}
            />

            <ErrorLabel error={props.error?.streetAddress2} />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          <div>
            <label htmlFor="addressLocality" className="block text-sm font-medium leading-6 text-gray-900">
              {t('online-order.fill-contact-page.form-input.delivery.city.label', 'City')}
            </label>
            <input
              type="text"
              name="addressLocality"
              autoComplete="address-level2"
              className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder={t('online-order.fill-contact-page.form-input.delivery.city.placeholder', 'City')}
              value={props.values.addressLocality}
              onChange={props.onChange}
            />

            <ErrorLabel error={props.error?.addressLocality} />
          </div>

          {/*<div>*/}
          {/*  <label htmlFor="state" className="block text-sm font-medium leading-6 text-gray-900">*/}
          {/*    State*/}
          {/*  </label>*/}
          {/*  <input*/}
          {/*    type="text"*/}
          {/*    name="addressRegion"*/}
          {/*    autoComplete="address-level1"*/}
          {/*    className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"*/}
          {/*    placeholder="State"*/}
          {/*    value={props.values.addressRegion}*/}
          {/*    onChange={props.onChange}*/}
          {/*  />*/}

          {/*  <ErrorLabel error={props.error?.addressRegion} />*/}
          {/*</div>*/}

          <div>
            <label htmlFor="postal-code" className="block text-sm font-medium leading-6 text-gray-900">
              {t('online-order.fill-contact-page.form-input.delivery.postal-code.label', 'Postal code')}
            </label>
            <input
              type="text"
              name="postalCode"
              autoComplete="postal-code"
              className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder={t('online-order.fill-contact-page.form-input.delivery.postal-code.placeholder', 'Zip')}
              value={props.values.postalCode}
              onChange={props.onChange}
            />

            <ErrorLabel error={props.error?.postalCode} />
          </div>
        </div>
      </div>
    </AddressAutofill>
  );
};

function ErrorLabel(props: { error: string | undefined }) {
  return (
    props.error && (
      <p className="mt-2 text-sm text-red-600" id="email-error">
        {props.error}
      </p>
    )
  );
}

export default AutofillAddressInput;

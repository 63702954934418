import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
const TableReservationCreateBranchError = () => {
  return (
    <>
      <div className="max-w-xl h-screen mx-auto h-full flex justify-center items-center">
        <div className="flex flex-grow flex-col gap-y-8 border border-1 px-8 py-8 shadow rounded-md mx-2">
          <div className="flex items-center gap-2">
            <ExclamationTriangleIcon className="w-8 h-8 text-red-500 text-center" />
            <p className="text-lg font-semibold">The system is not working</p>
          </div>
          <div className="italic text-gray-500">
            The current restaurant is temporarily closed
          </div>
          <div className="flex justify-end">
            <button
              onClick={() => {
                history.back()
              }}
              className="py-2 px-3 bg-indigo-600 text-white rounded-md font-medium max-sm:grow"
            >
              Back to home page
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TableReservationCreateBranchError;

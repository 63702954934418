import { MenuProductItemFragment } from '@app/graphql/types/graphql.ts';
import { getFragmentData } from '@app/graphql/types';
import {
  MenuProductConfigurationFragment,
  MenuProductConfigurationValueFragment,
} from '@app/page/online-order/model/menu-product-fragment.ts';

export function calculatePickedProduct(configurationValues: string[], product: MenuProductItemFragment, count: number) {
  return (
    configurationValues.reduce((acc, id) => {
      const value = product.configurations
      .map((config) => getFragmentData(MenuProductConfigurationFragment, config))
      .map((config) => getFragmentData(MenuProductConfigurationValueFragment, config.values))
      .flat()
      .find((value) => value.id === id);

      return acc + (value?.price ?? 0);
    }, 0) * count
  );
}

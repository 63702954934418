import CurrencyView from '@app/components/price/currency-view.tsx';
import PrimaryButton from '@app/components/primary-button.tsx';
import { useProductPicker } from '@app/page/online-order/product-picker/logic/use-product-picker.ts';
import { MenuItemFragment } from '@app/graphql/types/graphql.ts';
import SecondaryButton from '@app/components/secondary-button.tsx';
import LeftModalButton from '@app/components/left-modal-button.tsx';
import Cart from '@app/page/online-order/product-picker/component/cart.tsx';
import { calculateTotal } from '@app/page/online-order/product-picker/logic/calculate-total.tsx';
import DialogButton from '@app/components/dialog-button.tsx';

const CartBar = (props: { menu: MenuItemFragment; onCreate?: () => void; disableOrderButton?: boolean }) => {
  const { menu } = props;

  const pickedProducts = useProductPicker((state) => state.products);

  // Calculate total
  const total = calculateTotal(pickedProducts, menu);
  //calculate quantity
  const quantity = pickedProducts.reduce((acc, pickedProduct) => acc + pickedProduct.quantity, 0);

  return (
    <div className="fixed bottom-0 my-auto flex h-16 w-full flex-col justify-center rounded-t-2xl border-t bg-gray-50 lg:hidden">
      <div className="flex items-center justify-center space-x-4 px-4">
        <LeftModalButton title="Your cart" content={<Cart menu={menu} />}>
          <SecondaryButton>{quantity} product(s)</SecondaryButton>
        </LeftModalButton>

        <div className="flex-grow" />

        <CurrencyView price={total} />

        <DialogButton>
          <PrimaryButton className="w-20" onClick={props.onCreate} disabled={props.disableOrderButton}>
            Order
          </PrimaryButton>
        </DialogButton>
      </div>
    </div>
  );
};

export default CartBar;

import {
  OnlineOrderBranchConfigurationItemFragment,
  OnlineOrderDeliveryMode,
  OnlineOrderItemFragment,
} from '@app/graphql/types/graphql';
import CurrencyView from '@app/components/price/currency-view.tsx';
import { OnlineOrderProductsFragment } from '@app/page/online-order/model/online-order-fragment.ts';
import { getFragmentData } from '@app/graphql/types';
import CDNLink from '@app/utils/cdn-link.ts';
import { PhotoIcon } from '@heroicons/react/24/solid';
import SecondaryButton from '@app/components/secondary-button.tsx';
import DialogButton from '@app/components/dialog-button.tsx';
import ModifyProductCart from '@app/page/online-order/fill-contact-info/component/modify-product-cart.tsx';
import { CartSideBarPreviewMode } from '../utils/cart-side-bar-preview-mode-enum';
import { useTranslate } from '@tolgee/react';

function ProductPriceDetail(props: { price: number; quantity: number }) {
  return (
    <div className="flex flex-shrink-0 items-end gap-1">
      <div className="flex-none text-sm font-medium text-gray-500">
        <CurrencyView price={props.price} /> x {props.quantity} =
      </div>

      <CurrencyView className="text-base font-medium" price={props.price * props.quantity} />
    </div>
  );
}

const CartSideBarPreview = (props: {
  order: OnlineOrderItemFragment;
  mode: CartSideBarPreviewMode;
  onlineOrderBranchConfiguration?: OnlineOrderBranchConfigurationItemFragment;
  deliveryMode?: OnlineOrderDeliveryMode;
  editable?: boolean;
}) => {
  const { mode } = props;
  const editable = props.editable ?? true;

  const products = getFragmentData(OnlineOrderProductsFragment, props.order.onlineOrderProducts);

  const sortedProducts = [...products].sort((a, b) => {
    return a.id.localeCompare(b.id);
  });

  let discount = props.onlineOrderBranchConfiguration?.pickupDiscountAmount ?? 0;
  switch (props.onlineOrderBranchConfiguration?.pickupDiscountType.toString()) {
    case 'PERCENTAGE':
      discount = (props.order.basePrice ?? 0) * (discount / 100);
      break;
    case 'AMOUNT':
      break;
    default:
      discount = 0;
      break;
  }
  const { t } = useTranslate();
  return (
    <div className="bg-gray-50 p-8">
      <h2 id="summary-heading" className="text-lg font-medium text-gray-900">
        {t('online-order.review-page.cart-side-bar-preview.order-summary', 'Order summary')}
      </h2>

      <ul role="list" className="divide-y divide-gray-200 text-sm font-medium text-gray-900">
        {sortedProducts.map((pickedProduct) => {
          const configurationText = pickedProduct.configurations
            .map((config) => {
              return config.value;
            })
            .join(', ');

          let image: string | null = null;
          if (pickedProduct.menuProduct) {
            image = pickedProduct.menuProduct.images.length > 0 ? pickedProduct.menuProduct.images[0] : null;
          }

          const productPrice = pickedProduct.configurations.reduce((acc, config) => {
            return acc + config.price;
          }, 0);

          return (
            <li key={pickedProduct.id} className="flex items-start space-x-4 py-6">
              {image ? (
                <img
                  src={CDNLink(image)}
                  alt={pickedProduct.title}
                  className="h-20 w-20 flex-none rounded-md object-cover object-center"
                />
              ) : (
                <div className="flex h-20 w-20 shrink items-center justify-center rounded-l-md border">
                  <PhotoIcon className="h-6 w-6 fill-gray-400" />
                </div>
              )}

              <div className="flex-auto space-y-1">
                <h3>{pickedProduct.title}</h3>
                {pickedProduct.configurations.length > 0 && (
                  <p className="line-clamp-3 text-gray-500">{configurationText}</p>
                )}

                <div className="md:hidden">
                  <ProductPriceDetail price={productPrice} quantity={pickedProduct.quantity} />
                </div>

                {editable && (
                  <DialogButton content={<ModifyProductCart onlineOrderProduct={pickedProduct} />}>
                    <SecondaryButton>
                      {t('online-order.review-page.cart-side-bar-preview.button.edit-cart', 'Edit')}
                    </SecondaryButton>
                  </DialogButton>
                )}
              </div>

              <div className="hidden md:inline-flex">
                <ProductPriceDetail price={productPrice} quantity={pickedProduct.quantity} />
              </div>
            </li>
          );
        })}
      </ul>

      {mode === CartSideBarPreviewMode.OnlyProducts && (
        <div>
          <div className="flex items-end justify-end border-t pb-2 pt-4">
            <p className="text-base font-semibold">
              {t('online-order.review-page.cart-side-bar-preview.subtotal', 'Subtotal')}
            </p>
            <div className="flex-grow" />
            <CurrencyView className="ml-2 text-2xl font-medium" price={props.order.basePrice} />
          </div>
          {props.deliveryMode?.toString() === 'PICKUP' && props.order.basePrice && discount > 0 && (
            <div>
              <div className="mt-4 flex flex-col gap-2 border-y py-4">
                {props.deliveryMode === OnlineOrderDeliveryMode.Pickup && (
                  <PriceRow label="Pickup discount" price={-discount} />
                )}
              </div>

              <div className="flex items-end justify-end pt-4">
                <p className="text-base font-semibold">Total price</p>
                <div className="flex-grow" />
                <CurrencyView
                  className="ml-2 text-2xl font-medium"
                  price={Math.max(
                    props.order.basePrice - discountAmount(props.order, props.onlineOrderBranchConfiguration),
                    0
                  )}
                />
              </div>
            </div>
          )}
        </div>
      )}

      {mode === CartSideBarPreviewMode.Full && (
        <>
          <div className="mt-4 flex flex-col gap-2 border-y py-4">
            <PriceRow
              label={t('online-order.review-page.cart-side-bar-preview.subtotal', 'Subtotal')}
              price={props.order.basePrice ?? 0}
            />

            {props.order.deliveryPrice && (
              <PriceRow
                label={t('online-order.review-page.cart-side-bar-preview.delivery-fees', 'Delivery fee')}
                price={props.order.deliveryPrice}
              />
            )}
            {props.order.pickupDiscount && (
              <PriceRow
                label={t('online-order.review-page.cart-side-bar-preview.pickup-discount', 'Pickup discount')}
                price={props.order.pickupDiscount}
              />
            )}
          </div>

          <div className="flex items-end justify-end pt-4">
            <p className="text-base font-semibold">
              {t('online-order.review-page.cart-side-bar-preview.total-price', 'Total price')}
            </p>
            <div className="flex-grow" />
            <CurrencyView className="ml-2 text-2xl font-medium" price={props.order.totalPrice} />
          </div>
        </>
      )}
    </div>
  );
};

function discountAmount(
  order: OnlineOrderItemFragment,
  onlineOrderBranchConfiguration?: OnlineOrderBranchConfigurationItemFragment
) {
  let discount = onlineOrderBranchConfiguration?.pickupDiscountAmount ?? 0;
  switch (onlineOrderBranchConfiguration?.pickupDiscountType.toString()) {
    case 'PERCENTAGE':
      discount = (order.basePrice ?? 0) * (discount / 100);
      break;
    case 'AMOUNT':
      break;
    default:
      discount = 0;
      break;
  }
  return discount;
}

const PriceRow = (props: { label: string; price?: number; info?: string }) => {
  return (
    <div className="flex justify-between">
      <p className="text-gray-500">{props.label}</p>
      {props.info && <p className="text-gray-500">{props.info}</p>}
      {props.price && <CurrencyView price={props.price} />}
    </div>
  );
};

export default CartSideBarPreview;

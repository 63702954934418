import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

export interface OnlineOrderDetailState {
  lastUpdateTimestamp: Date | null;
}

export interface OnlineOrderDetailActions {
  needUpdate: () => void;
}

export const useOnlineOrderDetailStore = create<OnlineOrderDetailState & OnlineOrderDetailActions>()(
  immer((set) => ({
    lastUpdateTimestamp: null,

    needUpdate: () => {
      set(() => {
        return {
          lastUpdateTimestamp: new Date(),
        };
      });
    },
  }))
);

import {useEffect, useState} from "react";
import {
  SubscribeEvent,
  TableReservationConfirmationStatus,
} from "../../../graphql/types/graphql";
import useGetTableReservation from "../../table-reservation/logic/use-get-table-reservation";
import useTableReservationUpdateSubscribe from "../../table-reservation/logic/use-get-table-reservation-subscribe";
import { getFragmentData } from "../../../graphql/types";
import { Storefront_TableReservationFragment } from "../../../graphql/types/storefront-table-reservation-fragment";

export const useTableReservation = (
  tableReservationId: string
) => {
  const [data, setData] = useState<TableReservationConfirmationStatus>();

  const {data: _initialData} = useGetTableReservation(tableReservationId);
  const {data: _updateData} = useTableReservationUpdateSubscribe(tableReservationId);

  useEffect(() => {
    if (!_initialData) {
      return;
    }

    setData(getFragmentData(Storefront_TableReservationFragment, _initialData.storefront_tableReservationStatus).confirmationStatus);
  }, [_initialData]);

  useEffect(() => {
    if (!_updateData) {
      return;
    }

    const tableReservationStatus = _updateData.storefront_tableReservationStatusUpdateSubscribe.tableReservation.confirmationStatus;

    switch (_updateData.storefront_tableReservationStatusUpdateSubscribe.event) {
      case SubscribeEvent.Update:
        setData(tableReservationStatus);
        break;
    }
  }, [_updateData]);

  return {data, reservation: getFragmentData(Storefront_TableReservationFragment, _initialData?.storefront_tableReservationStatus)};
};

import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { BranchItemFragment, MenuSectionItemFragment } from '@app/graphql/types/graphql.ts';
import { naturalCompare } from '@app/utils/natural-compare.ts';
import CDNLink from '@app/utils/cdn-link.ts';
import { LanguageSelector } from '@app/page/table-reservation/component/table-reservation-create-form.tsx';
import { useTranslate } from '@tolgee/react';

export function RestaurantPanelMobile(props: {
  branch: BranchItemFragment;
  sections: readonly MenuSectionItemFragment[];
}) {
  const sections = [...props.sections].sort((a, b) => naturalCompare(a.code, b.code));
  const { t } = useTranslate();
  return (
    <div className="flex min-h-28 w-full items-center justify-between border-b-2 bg-white px-4">
      <div className="flex items-center gap-x-2">
        {props.branch.company.logo && (
          <img className="w-12 rounded-md" src={CDNLink(props.branch.company.logo)} alt={props.branch.company.name} />
        )}
        <p className="pt-2 text-xl font-semibold">{props.branch.company.name}</p>
      </div>
      <div className="relative inline-block pt-1">
        <div className="pb-2">
          <LanguageSelector customerDefaultLanguage={props.branch.company.settings.customerDefaultLanguage} />
        </div>
        <Menu as="div" className="relative inline-block text-right">
          <div>
            <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              {t('online-order.nav-bar.category-on-mobile', 'Category')}
              <ChevronUpDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400" />
            </MenuButton>
          </div>
          <MenuItems
            transition
            className="absolute right-0 z-10 mt-2 w-56 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
          >
            {sections.map((section) => (
              <div className="py-1" key={section.id}>
                <MenuItem>
                  <a
                    key={section.id}
                    href={`#${section.id}`}
                    className="group flex items-center px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                  >
                    {section.name}
                  </a>
                </MenuItem>
              </div>
            ))}
          </MenuItems>
        </Menu>
      </div>
    </div>
  );
}

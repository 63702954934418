import { BranchItemFragment, MenuSectionItemFragment } from '@app/graphql/types/graphql.ts';
import CDNLink from '@app/utils/cdn-link.ts';
import { naturalCompare } from '@app/utils/natural-compare.ts';
import { useTranslate } from '@tolgee/react';
import { LanguageSelector } from '@app/page/table-reservation/component/table-reservation-create-form.tsx';

export function RestaurantPanel(props: { branch: BranchItemFragment; sections: readonly MenuSectionItemFragment[] }) {
  const sections = [...props.sections].sort((a, b) => naturalCompare(a.code, b.code));
  const { t } = useTranslate();
  return (
    <div className="hidden w-56 border-r pt-8 md:block">
      <div className="relative flex flex-col items-center gap-8 px-4">
        {/* Logo */}
        <div className="relative flex items-center justify-center gap-x-2">
          {props.branch.company.logo && (
            <img
              className="max-w-16 rounded-md"
              src={CDNLink(props.branch.company.logo)}
              alt={props.branch.company.name}
            />
          )}
          <p className="pt-2 text-xl font-semibold">{props.branch.company.name}</p>
        </div>

        {/* Address */}
        <div className="relative -right-4 flex w-full flex-col items-start italic text-gray-500">
          <p>{props.branch.streetAddress}</p>
          <p>
            {props.branch.postalCode} {props.branch.addressLocality}
          </p>
        </div>

        {/* Menu sections */}
        <div className="flex w-full flex-col items-start gap-1.5 px-2">
          <div>
            <div className="flex justify-between border-b">
              <span className="text-md mx-1 mb-2 pt-2 font-medium">
                {t('online-order.nav-bar.language', 'Language')}
              </span>
              <div className="pb-3 pl-3">
                <LanguageSelector customerDefaultLanguage={props.branch.company.settings.customerDefaultLanguage}/>
              </div>
            </div>
            <p className="mx-1 mb-2 pt-2 text-xl font-medium">{t('online-order.nav-bar.categories', 'Categories')}</p>
          </div>
          {sections.map((section) => (
            <a
              key={section.id}
              href={`#${section.id}`}
              className="w-full rounded-md px-2 py-2 font-medium text-gray-600 hover:bg-gray-100 hover:fill-indigo-500 hover:text-indigo-500"
            >
              {section.name}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

import { useProductPickerQuery } from '@app/page/online-order/product-picker/logic/use-product-picker-page-query.ts';
import ApplicationErrorView from '@app/module/error/application-error-view.tsx';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Loading from '@app/components/loading.tsx';
import UnavailableOnlineOrder from '@app/page/online-order/product-picker/component/unavailable-online-order.tsx';
import CriticalError from '@app/page/online-order/product-picker/component/critical-error.tsx';
import { getFragmentData } from '@app/graphql/types';
import { BranchFragment } from '@app/page/online-order/model/branch-fragment.ts';
import { RestaurantPanel } from '@app/page/online-order/product-picker/component/restaurant-panel.tsx';
import { MenuSectionFragment } from '@app/page/online-order/model/menu-section-fragment.ts';
import { MenuFragment, MenuProductFragment } from '@app/page/online-order/model/menu-product-fragment.ts';
import { CurrencyContext } from '@app/components/price/use-currency.ts';
import CartBar from '@app/page/online-order/product-picker/component/cart-bar.tsx';
import { useProductPicker } from '@app/page/online-order/product-picker/logic/use-product-picker.ts';
import { useCreateOnlineOrder } from '@app/page/online-order/product-picker/logic/use-create-online-order.ts';
import { captureException } from '@sentry/react';
import { useEffect } from 'react';
import { OnlineOrderBranchConfigurationFragment } from '@app/page/online-order/model/online-order-branch-configuration.ts';
import AlertBanner from './component/alert-banner';
import PageNotFound from './component/page-not-found';
import ProductPickerRoute from '@app/page/online-order/product-picker/component/product-picker-route.tsx';
import CartSideBar from './component/fixed-card-bar';
import TopBanner from '@app/page/online-order/banner/top-banner/top-banner.tsx';
import OverlayBanner from '@app/page/online-order/banner/overlay-banner/overlay-banner.tsx';
import { useTranslate } from '@tolgee/react';
import { RestaurantPanelMobile } from '@app/page/online-order/product-picker/component/restaurant-panel-mobile.tsx';
import { useLocalStorage } from 'usehooks-ts';
const ProductPickerPage = () => {
  const [params] = useSearchParams();
  const [customerLanguage, setCustomerLanguage] = useLocalStorage('language', 'de');
  const branchID = params.get('branchID');

  const navigate = useNavigate();
  const [createOnlineOrder, { loading: creatingOnlineOrder, error: creatingError }] = useCreateOnlineOrder();

  const { data, loading, error: loadingError } = useProductPickerQuery(branchID);
  const pickedProducts = useProductPicker((state) => state.products);

  const setupStore = useProductPicker((state) => state.setup);
  const clearCart = useProductPicker((state) => state.clear);

  const branch = getFragmentData(BranchFragment, data?.storefront_branch);
  const configuration = getFragmentData(
    OnlineOrderBranchConfigurationFragment,
    data?.storefront_onlineOrderBranchConfiguration
  );
  const menu = getFragmentData(MenuFragment, configuration?.menu);
  const sections = getFragmentData(MenuSectionFragment, menu?.sections) ?? [];
  const products = getFragmentData(MenuProductFragment, menu?.menuProducts) ?? [];

  const isOpenNow =
    data?.storefront_isOnlineOrderOpen.isDeliveryAvailable === true ||
    data?.storefront_isOnlineOrderOpen.isPickupAvailable === true;

  const error = loadingError ?? creatingError;

  useEffect(() => {
    if (branch) {
      setupStore(branch.id);
      setCustomerLanguage(branch.company.settings.customerDefaultLanguage);
    }
  }, [branch, setCustomerLanguage, setupStore]);

  // Check
  if (loading) return <Loading />;

  if (loadingError) {
    return <CriticalError />;
  }

  if (!branch) {
    return <PageNotFound />;
  }

  if (!configuration || !menu) {
    return <PageNotFound />;
  }

  if (!configuration.deliveryEnable && !configuration.pickupEnable) {
    return <UnavailableOnlineOrder website={branch.website ?? ''} />;
  }

  // Handler
  const createOrderHandler = () => {
    createOnlineOrder({
      variables: {
        branchID: branch.id,
        input: {
          customerLanguage: customerLanguage,
          pickedProducts: pickedProducts.map((product) => ({
            productID: product.productID,
            quantity: product.quantity,
            configurations: product.configurations.map((config) => ({
              configurationID: config.configurationID,
              valueID: config.valueID,
            })),
          })),
        },
      },
    })
      .then((onlineOrder) => {
        if (!onlineOrder.data?.storefront_createOnlineOrder) {
          return;
        }

        clearCart();
        navigate(`/online-order/${onlineOrder.data.storefront_createOnlineOrder.id}`);
      })
      .catch((error) => {
        captureException(error);
      });
  };

  return (
    <CurrencyContext.Provider value={branch.company.settings.currency}>
      {(creatingOnlineOrder || !isOpenNow) && <OnlineOrderAlertBanner isOpenNow={isOpenNow} />}

      <div className="flex h-screen flex-col overflow-hidden">
        <ApplicationErrorView className="my-4" error={error} />

        <div className="flex overflow-y-scroll">
          <div className="overflow-y-scrol h-screen overflow-x-visible">
            <RestaurantPanel branch={branch} sections={sections} />
          </div>

          <div className="mb-16 grow overflow-y-scroll">
            <div className="sticky top-0 z-30 w-full md:hidden lg:hidden">
              <RestaurantPanelMobile branch={branch} sections={sections} />
              <div className="md:pt-0 lg:pt-0">
                <TopBanner branchID={branch.id}></TopBanner>
              </div>
            </div>

            <div className={`sticky top-0 hidden md:block ${isOpenNow ? 'lg:pt-0' : 'lg:pt-12'} md:pt-0`}>
              <TopBanner branchID={branchID} />
            </div>

            <OverlayBanner branchID={branchID} />
            <ProductPickerRoute
              timezone={branch.company.settings.timezone}
              sections={sections}
              products={products}
              style={configuration.categoryStyle}
            />
          </div>

          <div className="no-scrollbar hidden shrink-0 overflow-y-scroll lg:block">
            <CartSideBar
              menu={menu}
              onCreate={createOrderHandler}
              disableOrderButton={creatingOnlineOrder || !isOpenNow}
            />
          </div>
        </div>

        <CartBar menu={menu} onCreate={createOrderHandler} disableOrderButton={creatingOnlineOrder || !isOpenNow} />
      </div>
    </CurrencyContext.Provider>
  );
};

function OnlineOrderAlertBanner({ isOpenNow }: { isOpenNow: boolean }) {
  const { t } = useTranslate();
  if (!isOpenNow) {
    return (
      <>
        <AlertBanner className="fixed z-50" description={t('online-order.picker-page.not-open-alert.description')} />
        <div className="fixed left-0 top-0 z-40 h-full w-full bg-gray-900 bg-opacity-50"></div>
      </>
    );
  } else {
    return <></>;
  }
}

export default ProductPickerPage;

import { Document, Page } from 'react-pdf';
import { useState, useEffect } from 'react';
import 'react-awesome-slider/dist/styles.css';

const MenuPreviewPdf = (props: { pdfURL: string; className?: string }) => {
  const { pdfURL } = props;

  const [numPages, setNumPages] = useState<number>();

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const [scale, setScale] = useState(1);

  useEffect(() => {
    function handleResize() {
      if (window.matchMedia('(max-width: 480px)').matches) {
        setScale(0.6);
      } else if (window.matchMedia('(max-width: 768px)').matches) {
        setScale(0.8);
      } else {
        setScale(1);
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <Document file={pdfURL} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (_, index) => (
          <div key={index} className="pb-2">
            <Page scale={scale} renderAnnotationLayer={false} renderTextLayer={false} pageNumber={index + 1} />
          </div>
        ))}
      </Document>
    </div>
  );
};

export default MenuPreviewPdf;

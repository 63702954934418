import { formatCurrency } from '../../../components/price/currency-formatter.ts';

interface FoodCardMobileProps {
  title: string;
  description: string;
  image: string | null;
  foodType: string | undefined;
  configurations: {
    values: { price: number; name: string }[];
    type: string;
  }[];
  currency?: string;
}

const FoodCardMobile: React.FC<FoodCardMobileProps> = ({
  title,
  description,
  image,
  foodType,
  configurations,
  currency,
}: FoodCardMobileProps) => {
  const host: string = import.meta.env.VITE_CDN_HOST as string;
  return (
    <div className="ml-2 mr-2 mt-2 flex h-full w-full flex-row justify-between overflow-hidden rounded-lg bg-gray-100 shadow-lg lg:hidden">
      <div className="flex w-2/3 flex-col p-2">
        <div className="inline-block">
          <span className="text-primary poppins mb-4 inline-block rounded-full border border-red-500 bg-red-100 px-4 py-1 text-sm">
            {foodType}
          </span>
        </div>
        <p className="font-serif text-lg font-medium">{title}</p>
        <span className="font-thin">{description.slice(0, 50)}</span>

        <div className="w-full">
          {configurations.map((item, index) => (
            <div key={index} className="">
              {item.values.map((value, index) => {
                return (
                  <div key={index} className="flex">
                    <span>{value.name}</span>
                    <span className="ml-3"></span>

                    <span className="text-blue-400">{formatCurrency(value.price, currency ?? 'USD')}</span>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
      <div
        className="relative m-2 w-1/4 rounded-lg bg-cover bg-center object-cover shadow-lg"
        style={{ backgroundImage: `url(${host}/${image ?? ''})` }}
        aria-label="food image"
      ></div>
    </div>
  );
};

export default FoodCardMobile;

import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { createJSONStorage, persist } from 'zustand/middleware';
import { OnlineOrderProductsItemFragment } from '@app/graphql/types/graphql.ts';

export interface PickedProduct {
  productID: string;
  quantity: number;
  configurations: PickedConfiguration[];
}

export function pickedProductID(product: PickedProduct) {
  const values = [...product.configurations].sort((a, b) => a.configurationID.localeCompare(b.configurationID)).map((c) => c.valueID);
  return `${product.productID}-${values.join('-')}`;
}

export interface PickedConfiguration {
  configurationID: string;
  valueID: string;
}

export interface ProductPickerState {
  branchID: string | null;
  products: PickedProduct[];
}

export interface ProductPickerActions {
  setup: (branchID: string) => void;
  continue: (pickedProducts: readonly OnlineOrderProductsItemFragment[]) => void;

  add: (pickedProduct: PickedProduct) => void;
  delete: (id: string) => void;
  decrease: (id: string) => void;
  clear: () => void;
}

export const useProductPicker = create<ProductPickerState & ProductPickerActions>()(
  persist(
    immer((set, get) => ({
      branchID: null,
      products: [],

      setup: (branchID: string) => {
        if (get().branchID === branchID) {
          return;
        } else {
          set(() => {
            return {
              branchID: branchID,
              products: [],
            };
          });
        }
      },

      continue:  (pickedProducts: readonly OnlineOrderProductsItemFragment[]) => {
        // Continue with picked products
        set((state) => {
          const transformedPickedProducts: PickedProduct[] = [];

          for (const pickedProduct of pickedProducts) {
            if (!pickedProduct.menuProduct) {
              continue;
            }

            let configurationMissing = false;
            const configurations: PickedConfiguration[] = [];
            for (const configuration of pickedProduct.configurations) {
              if (!configuration.menuProductConfigurationId || !configuration.menuProductConfigurationValueId) {
                configurationMissing = true;
                break;
              }

              configurations.push({
                configurationID: configuration.menuProductConfigurationId,
                valueID: configuration.menuProductConfigurationValueId,
              });
            }

            if (configurationMissing) {
              continue;
            }

            transformedPickedProducts.push({
              productID: pickedProduct.menuProduct.id,
              quantity: pickedProduct.quantity,
              configurations: configurations,
            });
          }

          // Set state
          state.products = Array.from(transformedPickedProducts);
        });

      },

      add: (pickedProduct: PickedProduct) => {
        set((state) => {
          const existingProduct = state.products.find((p) => pickedProductID(p) === pickedProductID(pickedProduct));

          if (existingProduct) {
            existingProduct.quantity += pickedProduct.quantity;
          } else {
            state.products.push(pickedProduct);
          }
        });
      },

      delete: (id: string) => {
        set((state) => {
          state.products = state.products.filter((p) => pickedProductID(p) !== id);
        });
      },

      decrease: (id: string) => {
        set((state) => {
          const product = state.products.find((p) => pickedProductID(p) === id);
          if (product) {
            product.quantity -= 1;
            if (product.quantity <= 0) {
              state.products = state.products.filter((p) => pickedProductID(p) !== id);
            }
          }
        });
      },

      clear: () => {
        set((state) => {
          state.products = [];
        });
      },
    })),
    {
      name: 'product-picker',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

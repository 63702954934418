import { MenuProductItemFragment, ProductConfigurationType } from '@app/graphql/types/graphql.ts';
import CDNLink from '@app/utils/cdn-link.ts';
import { getFragmentData } from '@app/graphql/types';
import {
  MenuProductConfigurationFragment,
  MenuProductConfigurationValueFragment,
} from '@app/page/online-order/model/menu-product-fragment.ts';
import CurrencyView from '@app/components/price/currency-view.tsx';
import AllergicDisplay from '@app/page/online-order/product-picker/component/allergic-display.tsx';
import ProductLabelIcon from '@app/page/online-order/product-picker/component/product-label-icon.tsx';
import ProductAddButton from '../product-add-button';
import { useTranslate } from '@tolgee/react';

const ProductGridStyle = (props: { product: MenuProductItemFragment }) => {
  const firstImage = props.product.images.length > 0 ? props.product.images[0] : null;

  const configurations = props.product.configurations.map((config) => {
    const configuration = getFragmentData(MenuProductConfigurationFragment, config);

    return {
      ...configuration,
      values: configuration.values.map((value) => {
        return getFragmentData(MenuProductConfigurationValueFragment, value);
      }),
    };
  });

  const singlePrice = configurations.length === 1 && configurations[0].values.length === 1;
  const basePrices =
    configurations
      .find((config) => config.type === ProductConfigurationType.Base)
      ?.values.map((value) => value.price) ?? [];

  const minBasePrice = Math.min(...basePrices);
  const { t } = useTranslate();

  return (
    <div className="flex space-x-4 rounded-xl bg-white outline outline-1 outline-gray-200">
      {firstImage ? (
        <div className="h-32 w-32 shrink-0">
          <img src={CDNLink(firstImage)} className="h-32 w-32 rounded-l-xl object-cover" alt="product-image" />
        </div>
      ) : (
        <div />
      )}
      <div className="flex flex-col space-y-1 py-2 pr-2">
        <p className="flex items-center gap-x-2 text-xl font-semibold">
          <span> {props.product.code}.</span> {props.product.title}
          <ul className={'flex gap-x-1'}>
            {props.product.labels?.sort().map((label) => {
              return (
                <li key={label} className="h-5 w-5">
                  <ProductLabelIcon key={label} label={label} />
                </li>
              );
            })}
          </ul>
        </p>
        <p className="line-clamp-2 text-sm italic text-gray-500">{props.product.description}</p>

        <AllergicDisplay allergics={props.product.allergics ?? []} />

        <div className="flex-grow"></div>

        <div className="flex items-center space-x-2">
          <ProductAddButton product={props.product} />

          {singlePrice ? (
            <CurrencyView price={minBasePrice} />
          ) : (
            <div>
              {t('online-order.picker-page.product-grid-style.start-from', 'Start from')}{' '}
              <CurrencyView price={minBasePrice} />
            </div>
          )}
        </div>
      </div>

      <div className="flex-grow" />
    </div>
  );
};

export default ProductGridStyle;

import { MenuItemFragment } from '@app/graphql/types/graphql';
import Cart from './cart';
import PrimaryButton from '@app/components/primary-button';
import { useTranslate } from '@tolgee/react';

export default function FixedCardBar(props: {
  menu: MenuItemFragment;
  onCreate?: () => void;
  disableOrderButton?: boolean;
}) {
  const { menu } = props;
  const { t } = useTranslate();
  return (
    <div className="w-80 pr-2">
      <div className="flex grow flex-col overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
        <div className="flex h-16 shrink-0 items-center">
          <p className="ml-2 text-2xl font-semibold leading-6 text-gray-900">
            {t('online-order.cart-bar.header', 'Cart')}
          </p>
        </div>
        <nav className="flex flex-1 flex-col">
          <Cart menu={menu} />
        </nav>
        <div className="float w-full justify-end pt-4">
          <PrimaryButton className="float-right w-20" onClick={props.onCreate} disabled={props.disableOrderButton}>
            {t('online-order.cart-bar.button.order', 'Order')}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}

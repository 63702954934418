import { useSubscription } from "@apollo/client";
import { graphql } from '@app/graphql/types';

const QUERY = graphql(`
    subscription OnUpdateOnlineOrder($id: ID!) {
        storefront_onUpdateOnlineOrder(id: $id) {
            onlineOrderID
            timestamp
        }
    }
`);

export default function useOnlineOrderUpdateSubscription(id: string| undefined) {
  return useSubscription(QUERY, {
    variables: { id: id ?? '' },
  });
}

import { createContext, useContext } from 'react';

export const CurrencyContext = createContext<string | undefined>(undefined);

export function useCurrency(): string {
  const currency = useContext(CurrencyContext);
  if (currency === undefined) {
    throw new Error('CurrencyContext is not provided');
  }
  return currency;
}

import { formatCurrency } from './currency-formatter';
import { useCurrency } from '@app/components/price/use-currency.ts';

export default function CurrencyView({ price, className }: { price: number | null | undefined; className?: string }) {
  const currency = useCurrency();
  if (price === null || price === undefined) {
    return <div className={className}>N/A</div>;
  }

  return <span className={className}>{formatCurrency(price, currency)}</span>;
}

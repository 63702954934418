import { DaysOfWeek, MenuProductItemFragment, MenuSectionItemFragment } from '@app/graphql/types/graphql.ts';
import classNames from 'classnames';
import { naturalCompare } from '@app/utils/natural-compare.ts';
import ProductListStyle from '@app/page/online-order/product-picker/component/product-picker-list-style/product-list-style.tsx';
import moment from 'moment';

const dayList = [
  DaysOfWeek.Sunday,
  DaysOfWeek.Monday,
  DaysOfWeek.Tuesday,
  DaysOfWeek.Wednesday,
  DaysOfWeek.Thursday,
  DaysOfWeek.Friday,
  DaysOfWeek.Saturday,
];

export default function ProductPickerListStyle(props: {
  sections: readonly MenuSectionItemFragment[];
  products: readonly MenuProductItemFragment[];
  timezone: string;
}) {
  const sections = [...props.sections].sort((a, b) => naturalCompare(a.code, b.code));
  return (
    <div className="w-full px-1 md:px-8 lg:px-2 xl:px-10">
      {sections.map((section) => {
        const products = props.products
          .filter((product) => product.sectionId === section.id)
          .sort((a, b) => a.title.localeCompare(b.title));

        const checkSectionEnable = section.enabled;

        let checkSectionRangeTime = true;
        if (
          section.timeRule.__typename == 'MenuSectionRangeTimeRule' &&
          typeof section.timeRule.isAllDays == 'boolean' &&
          section.timeRule.start &&
          section.timeRule.end &&
          section.timeRule.daysOfWeek
        ) {
          const now = moment().tz(props.timezone);
          const startTime = moment(now)
            .set('hours', parseInt(section.timeRule.start.slice(0, 2)))
            .set('minutes', parseInt(section.timeRule.start.slice(3)));
          const endTime = moment(now)
            .set('hours', parseInt(section.timeRule.end.slice(0, 2)))
            .set('minutes', parseInt(section.timeRule.end.slice(3)));

          let checkRangeTimeHour = true;
          if (section.timeRule.isAllDays) {
            checkRangeTimeHour = true;
          } else {
            checkRangeTimeHour = moment().isBetween(startTime, endTime);
          }
          const checkRangeTimeDay = section.timeRule.daysOfWeek.includes(dayList[moment().tz('Europe/Berlin').day()]);

          checkSectionRangeTime = checkRangeTimeHour && checkRangeTimeDay;
        }

        if (!checkSectionEnable) {
          return null;
        }

        return (
          <div id={section.id} key={section.id} className="w-full py-4 md:px-4">
            <div className="flex items-center gap-2 p-2 pt-8">
              <span className="text-xl font-semibold tracking-widest text-gray-800">{section.name}</span>
              {section.timeRule.__typename == 'MenuSectionRangeTimeRule' && (
                <div className="flex text-sm font-semibold tracking-widest text-red-600">
                  {'('}
                  <span className="">{`Open from ${section.timeRule.start} to ${section.timeRule.end}`}</span>
                  {!section.timeRule.isAllDays && (
                    <ul className="ml-1 flex gap-1">
                      <>
                        <span>on</span>

                        {section.timeRule.daysOfWeek
                          ?.map((day) => day.slice(0, 3))
                          .join(', ')
                          .toLowerCase()}
                      </>
                    </ul>
                  )}
                  {')'}
                </div>
              )}
            </div>

            <div className={classNames('mt-1 flex flex-col gap-3')}>
              {products
                .sort((a, b) => naturalCompare(a.code, b.code))
                .map((product) => {
                  return (
                    <div key={product.id} className={classNames(!checkSectionRangeTime ? 'relative opacity-45' : '')}>
                      <ProductListStyle product={product} />
                      {!checkSectionRangeTime && <div className="absolute inset-0 z-50"></div>}
                    </div>
                  );
                })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

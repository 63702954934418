import { Radio, RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { useEffect } from 'react';

export interface CardSelectionData<T> {
  id: string;

  value: T;

  title: string;
  description: string;
  additionalText: string;
}

export function CardSelection<T>(props: {
  title?: string;
  data: CardSelectionData<T>[];
  value: CardSelectionData<T>;
  onChange: (value: CardSelectionData<T>) => void;
}) {
  const { data, value, onChange } = props;

  useEffect(() => {
    if (data.length == 1){
      onChange(data[0]);
    }
  })

  return (
    <fieldset>
      <legend className="text-sm font-semibold leading-6 text-gray-900">{props.title}</legend>
      <RadioGroup value={value} onChange={onChange} className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
        {data.map((item) => (
          <Radio
            key={item.id}
            value={item}
            aria-label={item.title}
            aria-description={item.description}
            className={({ focus }) =>
              classNames(
                focus ? 'border-indigo-600 ring-2 ring-indigo-600' : '',
                !focus ? 'border-gray-300' : '',
                'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
              )
            }
          >
            {({ checked, focus }) => (
              <>
                <span className="flex flex-1">
                  <span className="flex flex-col">
                    <span className="block text-sm font-medium text-gray-900">{item.title}</span>
                    <span className="mt-1 flex items-center text-sm text-gray-500">{item.description}</span>
                    <span className="mt-6 text-sm font-medium text-gray-900">{item.additionalText}</span>
                  </span>
                </span>
                <CheckCircleIcon
                  className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    checked ? 'border-indigo-600' : 'border-transparent',
                    focus ? 'border' : 'border-2',
                    'pointer-events-none absolute -inset-px rounded-lg'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </Radio>
        ))}
      </RadioGroup>
    </fieldset>
  );
}

import { formatCurrency } from '../../../components/price/currency-formatter.ts';

interface FoodCardProps{
  title: string;
  description: string;
  image: string | null;
  foodType: string | undefined;
  configurations: {
    values: { price: number; name: string }[];
    type: string;
  }[];
  currency?: string;
}

const FoodCard: React.FC<FoodCardProps> = ({ image, title, description, foodType, configurations, currency } : FoodCardProps) => {
  const host: string = import.meta.env.VITE_CDN_HOST as string;
  const placeholderImage =
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-image'%3E%3Crect x='3' y='3' width='18' height='18' rx='2' ry='2'%3E%3C/rect%3E%3Ccircle cx='8.5' cy='8.5' r='1.5'%3E%3C/circle%3E%3Cpolyline points='21 15 16 10 5 21'%3E%3C/polyline%3E%3C/svg%3E";
  console.log('currency', currency, typeof currency);

  return (
    <div className="rounded-lg border border-gray-200 bg-blue-50 p-4">
      <span className="text-primary poppins mb-4 inline-block rounded-full border border-blue-500 bg-red-100 px-4 py-1 text-sm">
        {foodType}
      </span>
      {image ? (
        <div
          className="mx-auto h-64 w-64 rounded-full bg-cover bg-center"
          style={{ backgroundImage: `url(${host + '/' + image})` }}
          aria-label="food image"
        />
      ) : (
        <img
          src={placeholderImage}
          alt="No image available"
          className="w-50 h-50 mx-auto transform transition duration-300 hover:scale-105"
        />
      )}
      <div className="my-3 flex flex-col items-center space-y-2">
        <h1 className="poppins text-lg text-gray-900">{title}</h1>
        <p className="poppins text-center text-sm text-gray-500">{description.slice(0, 50)}</p>
        <div>
          {configurations.map(
            (
              item,
              index: number
            ) => (
              <div key={index}>
                {item.values.map((value, index) => {
                  return (
                    <div key={index} className="flex items-center justify-between">
                      <span className="text-blue-400">{formatCurrency(value.price, currency ?? 'USD')}</span>

                      <span>{value.name ? 'Base' : ''}</span>
                    </div>
                  );
                })}
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default FoodCard;

import { CloseButton, DialogTitle, useClose } from '@headlessui/react';
import { useState } from 'react';
import { OnlineOrderProductsItemFragment } from '@app/graphql/types/graphql.ts';
import SecondaryButton from '@app/components/secondary-button.tsx';
import { MinusIcon } from '@heroicons/react/24/outline';
import { PlusIcon } from '@heroicons/react/24/solid';
import PrimaryButton from '@app/components/primary-button.tsx';
import { useOnlineOrderModifyCart } from '@app/page/online-order/detail/logic/use-online-order-modify-cart.ts';
import { captureException } from '@sentry/react';
import ApplicationErrorView from '@app/module/error/application-error-view.tsx';

const ModifyProductCart = (props: { onlineOrderProduct: OnlineOrderProductsItemFragment }) => {
  const [amount, setAmount] = useState(props.onlineOrderProduct.quantity);
  const close = useClose();

  const [updateAmount, { loading, error }] = useOnlineOrderModifyCart();

  const decreaseHandler = () => {
    if (amount === 0) {
      return;
    }

    setAmount(amount - 1);
  };

  const increaseHandler = () => {
    setAmount(amount + 1);
  };

  const updateHandler = () => {
    updateAmount({
      variables: {
        onlineOrderProductID: props.onlineOrderProduct.id,
        amount,
      },
    })
      .then(() => {
        close();
      })
      .catch(captureException);
  };

  return (
    <>
      <DialogTitle>
        <p className="text-lg font-semibold">Modify product</p>
      </DialogTitle>

      <div className="mt-4 flex gap-4">
        <SecondaryButton onClick={decreaseHandler}>
          <MinusIcon className="w-4" />
        </SecondaryButton>

        <p>{amount}</p>

        <SecondaryButton onClick={increaseHandler}>
          <PlusIcon className="w-4" />
        </SecondaryButton>
      </div>

      <ApplicationErrorView error={error} />

      <div className="mt-4 flex items-center justify-end gap-4 border-t pt-2">
        <PrimaryButton disabled={loading} onClick={updateHandler}>
          Update
        </PrimaryButton>
        <CloseButton disabled={loading}>Cancel</CloseButton>
      </div>
    </>
  );
};

export default ModifyProductCart;

import { useMutation } from '@apollo/client';
import { graphql } from '@app/graphql/types';

export const QUERY = graphql(`
    mutation OnlineOrderFillPersonalData($onlineOrderID: ID!, $input: UserContactInfoInput!) {
        storefront_userFillsContactInfo(onlineOrderID: $onlineOrderID, input: $input)
    }
`);

export function useOnlineOrderFillPersonalData() {
  return useMutation(QUERY, {refetchQueries: ['OnlineOrderDetailPage']});
}

import { graphql } from '@app/graphql/types';

export const BranchFragment = graphql(`
    fragment BranchItem on StoreFront_Branch {
        id
        name
        website
        
        streetAddress
        addressLocality
        addressRegion
        postalCode
        addressCountry
        
        company {
            id
            logo
            name
            settings {
                currency
                customerDefaultLanguage
                timezone
            }
        }
    }
`);

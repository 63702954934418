import { CategoryStyle, MenuProductItemFragment, MenuSectionItemFragment } from '@app/graphql/types/graphql.ts';
import ProductPickerGridStyle from '@app/page/online-order/product-picker/component/product-picker-grid-style/product-picker-grid-style.tsx';
import ProductPickerListStyle from '@app/page/online-order/product-picker/component/product-picker-list-style/product-picker-list-style.tsx';

export default function ProductPickerRoute(props: {
  style: CategoryStyle | undefined;
  sections: readonly MenuSectionItemFragment[];
  products: readonly MenuProductItemFragment[];
  timezone: string
}) {
  return (
    <>
      {props.style == CategoryStyle.Style_1 && (
        <ProductPickerGridStyle sections={props.sections} products={props.products} />
      )}
      {props.style == CategoryStyle.Style_2 && (
        <ProductPickerListStyle timezone={props.timezone} sections={props.sections} products={props.products} />
      )}
    </>
  );
}

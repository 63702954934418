import { MenuProductItemFragment, MenuSectionItemFragment } from '@app/graphql/types/graphql.ts';
import classNames from 'classnames';
import { naturalCompare } from '@app/utils/natural-compare.ts';
import ProductGridStyle from '@app/page/online-order/product-picker/component/product-picker-grid-style/product-grid-style.tsx';

const ProductPickerGridStyle = (props: {
  sections: readonly MenuSectionItemFragment[];
  products: readonly MenuProductItemFragment[];
}) => {
  const sections = [...props.sections].sort((a, b) => naturalCompare(a.code, b.code))

  return (
    <div className="px-1">
      {sections.map((section) => {
        const products = props.products
          .filter((product) => product.sectionId === section.id)
          .sort((a, b) => a.title.localeCompare(b.title));

        return (
          <div id={section.id} key={section.id} className="py-4 md:px-4">
            <div className="p-2 pt-8 text-xl font-semibold tracking-widest text-gray-800">{section.name}</div>

            <div className={classNames('mt-1 grid flex-col gap-4', 'md:grid-cols-1 xl:grid-cols-2')}>
              {products
                .sort((a, b) => naturalCompare(a.code, b.code))
                .map((product) => {
                  return <ProductGridStyle key={product.id} product={product} />;
                })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProductPickerGridStyle;

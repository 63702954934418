import { graphql } from '@app/graphql/types';

export const MenuSectionFragment = graphql(`
  fragment MenuSectionItem on MenuSection {
    id
    name
    description
    code
    enabled
    timeRule{
      __typename
      ... on MenuSectionAlwaysTimeRule {
        type
      }
    
      __typename
      ... on MenuSectionRangeTimeRule {
        type
        start
        end
        daysOfWeek
        isAllDays
      }
    }
  }
`);

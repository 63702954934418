import { Menu, MenuButton, MenuHeading, MenuItem, MenuItems, MenuSection } from '@headlessui/react';
import sections from './nail-section-data';
import { services, Service } from './nail-service-data';
import classNames from 'classnames';
import { CheckIcon } from '@heroicons/react/24/outline';
import { useTranslate } from '@tolgee/react';

export default function NailServiceSelection(props: {
  serviceList: Service[];
  setServiceList: (newlist: Service[]) => void;
}) {
  const { t } = useTranslate();
  const handleTitle = () => {
    if (props.serviceList.length !== 1) {
      return `${t('reservation.create-form.form-input.nail-service.placeholder')}: ${String(props.serviceList.length)}`;
    }
    const uniqueService = props.serviceList[0];
    return uniqueService.name;
  };
  const handleClick = (service: Service) => {
    const equivalentService = props.serviceList.find((item) => item.name == service.name);
    if (equivalentService) {
      const newlist = props.serviceList.filter((item) => item.name !== equivalentService.name);
      props.setServiceList(newlist);
      return;
    }
    const newlist = props.serviceList.concat(service);
    props.setServiceList(newlist);
    return;
  };
  return (
    <Menu>
      <MenuButton className="h-10 w-full rounded-md border border-gray-300 text-sm italic text-gray-600 hover:bg-gray-50">
        <p className="max-w-3/4 mx-2 flex overflow-hidden text-nowrap">{handleTitle()}</p>
      </MenuButton>
      <div>
        <MenuItems
          anchor="bottom"
          className={
            'absolute h-96 rounded-md border-2 text-sm shadow-md [--anchor-gap:4px] [--anchor-padding:16px] min-[600px]:w-[465px]'
          }
        >
          {sections.map((section, index) => (
            <MenuSection key={index}>
              <MenuHeading className="bg-gray-100 py-2 text-center text-sm font-semibold text-red-500">
                {section}
              </MenuHeading>
              {services
                .filter((service) => service.section == section)
                .map((service, index) => {
                  const isSelected = props.serviceList.find((item) => item.id == service.id);
                  return (
                    <MenuItem key={index}>
                      <div
                        onClick={(e) => {
                          e.preventDefault();
                          handleClick(service);
                        }}
                        className={classNames(
                          'cursor-poiner grid grid-cols-3 py-2 pl-4 pr-8 text-gray-800 sm:grid-cols-4 md:hover:bg-indigo-600 md:hover:text-white',
                          isSelected ? 'border-y bg-indigo-600 text-white' : 'bg-white'
                        )}
                      >
                        <div className="col-span-2 grid grid-cols-5 items-center gap-1 sm:col-span-3">
                          <div className="flex justify-center">
                            {isSelected && <CheckIcon className="h-5 w-5 font-semibold" />}
                          </div>
                          <div className="col-span-4 cursor-pointer truncate text-wrap text-start">{service.name}</div>
                        </div>
                        <div className="cursor-pointer text-end">
                          <span className="mr-1 italic">({service.time})</span>
                          <span className="">{service.cost}</span>
                        </div>
                      </div>
                    </MenuItem>
                  );
                })}
            </MenuSection>
          ))}
        </MenuItems>
      </div>
    </Menu>
  );
}

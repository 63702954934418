import { useMutation } from '@apollo/client';
import { graphql } from '@app/graphql/types';

export const QUERY = graphql(`
  mutation OnlineOrderSetPickedProducts($onlineOrderID: ID!, $products: [OnlineOrderPickedProductInput!]!, $state: OnlineOrderUserState, $customerLanguage: String!) {
    storefront_onlineOrderSetPickedProducts(onlineOrderID: $onlineOrderID, products: $products, userState: $state, customerLanguage: $customerLanguage)
  }
`);

export function useOnlineOrderSetPickedProducts() {
  return useMutation(QUERY, {
    refetchQueries: ['OnlineOrderDetailPage'],
  });
}

import { useState } from 'react';
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import useStoreFrontBanners from '@app/page/online-order/banner/use-storefront_banners-query.ts';
import useStoreFrontBranch from '@app/page/online-order/banner/use-storefront_branch-query.ts';

export default function OverlayBanner(props: { branchID: string | null }) {
  const [open, setOpen] = useState(true);

  const branchID = props.branchID;

  // Fetch branch data
  const { data: branchData, error: branchError, loading: branchLoading } = useStoreFrontBranch(branchID ?? '');
  const companyId = branchData?.storefront_branch?.companyId;

  // Fetch banners data
  const {
    data: overlayBannersData,
    error: bannersError,
    loading: bannersLoading,
  } = useStoreFrontBanners(companyId ?? '', 'OVERLAY');

  // Handle loading and error states
  if (branchLoading || bannersLoading) return <div>Loading...</div>;
  if (branchError) return <div>Error loading branch data: {branchError.message}</div>;
  if (bannersError) return <div>Error loading banners data: {bannersError.message}</div>;

  const overlayBanner = overlayBannersData?.storefront_banners;

  // Extract image URL
  const host = import.meta.env.VITE_CDN_HOST as string;
  // Check if there's an image
  if (overlayBanner?.length === 0) {
    return null; // Return null if no image is available, this will not render the component
  }

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-screen items-center justify-center p-10 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-2 pb-2 pt-2 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-2 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="absolute right-0 top-0 pr-2 pt-2">
              <button
                type="button"
                onClick={() => {
                  setOpen(false);
                }}
                className="absolute right-1 top-1 z-10 inline-flex justify-center rounded-md bg-gray-600 px-1 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-900"
              >
                <XMarkIcon aria-hidden="true" className="h-5 w-5 text-white" />
              </button>
            </div>
            <div className="w-full">
              {overlayBanner?.map((banner, index) => {
                const imageUrl = banner.content.__typename == 'OverlayContent' ? banner.content.image ?? '' : '';
                return (
                  <div key={index} className="h-full w-full overflow-hidden">
                    <img className="h-full w-full object-cover" alt="preview" src={`${host}/${imageUrl}`} />
                  </div>
                );
              })}
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}

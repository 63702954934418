import { CheckIcon } from '@heroicons/react/24/solid';
import { ProgressStep } from '@app/components/progress.tsx';
import classNames from 'classnames';
import { useTranslate } from '@tolgee/react';

export default function Example(props: { steps: ProgressStep[] }) {
  const steps = props.steps;
  const { t } = useTranslate();
  return (
    <nav aria-label="Progress">
      <ol role="list" className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className="relative md:flex md:flex-1">
            {step.status === 'complete' ? (
              <div className="group flex w-full items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span
                    className={classNames(
                      `flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-black group-hover:bg-black`,
                      { 'bg-red-600': step.color === 'red' }
                    )}
                  >
                    <CheckIcon aria-hidden="true" className="h-6 w-6 text-white" />
                  </span>
                  <span
                    className={classNames(`ml-4 text-sm font-medium text-gray-500`, {
                      'text-semibold text-red-600': step.color === 'red',
                    })}
                  >
                    {t('online-order.detail-page.progress.' + step.name, step.name)}
                  </span>
                </span>
              </div>
            ) : step.status === 'current' ? (
              <div aria-current="step" className="flex items-center px-6 py-4 text-sm font-medium">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-800">
                  <span className="h-2.5 w-2.5 rounded-full bg-black" />
                </span>
                <span className="ml-4 text-sm font-medium text-black">
                  {t('online-order.detail-page.progress.' + step.name, step.name)}
                </span>
              </div>
            ) : (
              <div className="group flex items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                    <span className="text-gray-500 group-hover:text-gray-900"></span>
                  </span>
                  <span className={`ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900`}>
                    {t('online-order.detail-page.progress.' + step.name, step.name)}
                  </span>
                </span>
              </div>
            )}

            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div aria-hidden="true" className={`absolute right-0 top-0 hidden h-full w-5 md:block`}>
                  <svg
                    fill="none"
                    viewBox="0 0 22 80"
                    preserveAspectRatio="none"
                    className={`h-full w-full text-gray-300 ${step.status === 'current' ? '' : 'text-gray-300'}`}
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      stroke="currentcolor"
                      vectorEffect="non-scaling-stroke"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  );
}

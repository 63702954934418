import { useEffect, useState } from 'react';
import { useGetBranches } from './logic/use-get-branches';
import Center from '../../components/center';
import Spinner from '../../components/spinner';
import TableReservationCreateForm from './component/table-reservation-create-form';
import TableReservationCreateBranchError from './component/table-reservation-create-branch-error';
import { useSearchParams } from 'react-router-dom';

const TableReservationCreate = () => {
  const [params] = useSearchParams();
  const companyID = params.get('companyID');
  const branchID = params.get('branchID');

  const { data: branchesData, loading } = useGetBranches(companyID);
  const [selectedBranch, setSelectedBranch] = useState<string>('');

  const selectedBranchData = branchesData?.storefront_tableReservationBranches.find(
    (branch) => branch.id === selectedBranch
  );

  useEffect(() => {
    setSelectedBranch(branchID ?? '');
  }, [branchID]);

  useEffect(() => {
    if (selectedBranchData) {
      document.title = `Reservation at ${selectedBranchData.name}`;
    } else {
      document.title = 'Reservation';
    }
  }, [selectedBranchData]);

  if (companyID === null) {
    return <div>Company ID is required</div>;
  }

  if (!branchID) {
    return <div>BranchID is require</div>;
  }

  if (loading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  if (!selectedBranchData) {
    return <TableReservationCreateBranchError />;
  }

  return (
    <>
      <TableReservationCreateForm selectedBranch={selectedBranchData} branchID={branchID} companyID={companyID} />
    </>
  );
};

export default TableReservationCreate;

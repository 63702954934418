import { OnlineOrderItemFragment, OnlineOrderUserState } from '@app/graphql/types/graphql.ts';
import { useProductPickerQuery } from '@app/page/online-order/product-picker/logic/use-product-picker-page-query.ts';
import { useProductPicker } from '@app/page/online-order/product-picker/logic/use-product-picker.ts';
import { getFragmentData } from '@app/graphql/types';
import { BranchFragment } from '@app/page/online-order/model/branch-fragment.ts';
import Loading from '@app/components/loading.tsx';
import CriticalError from '@app/page/online-order/product-picker/component/critical-error.tsx';
import UnavailableOnlineOrder from '@app/page/online-order/product-picker/component/unavailable-online-order.tsx';
import { MenuFragment, MenuProductFragment } from '@app/page/online-order/model/menu-product-fragment.ts';
import { MenuSectionFragment } from '@app/page/online-order/model/menu-section-fragment.ts';
import { useEffect } from 'react';
import { OnlineOrderProductsFragment } from '@app/page/online-order/model/online-order-fragment.ts';
import ApplicationErrorView from '@app/module/error/application-error-view.tsx';
import { RestaurantPanel } from '@app/page/online-order/product-picker/component/restaurant-panel.tsx';
import CartBar from '@app/page/online-order/product-picker/component/cart-bar.tsx';
import { useOnlineOrderSetPickedProducts } from '@app/page/online-order/product-picker/logic/use-online-order-set-picked-products.ts';
import { captureException } from '@sentry/react';
import { OnlineOrderBranchConfigurationFragment } from '@app/page/online-order/model/online-order-branch-configuration.ts';
import CartSideBar from '@app/page/online-order/product-picker/component/fixed-card-bar.tsx';
import ProductPickerRoute from '@app/page/online-order/product-picker/component/product-picker-route.tsx';
import TopBanner from '@app/page/online-order/banner/top-banner/top-banner.tsx';
import { RestaurantPanelMobile } from '@app/page/online-order/product-picker/component/restaurant-panel-mobile.tsx';
import { useLocalStorage } from 'usehooks-ts';

const ProductRepickerPage = (props: { order: OnlineOrderItemFragment }) => {
  const [customerLanguage] = useLocalStorage('language', 'de');
  const branch = getFragmentData(BranchFragment, props.order.branch);

  const { data, loading, error } = useProductPickerQuery(branch.id);
  const [setPickedProducts, { loading: mutatingProducts }] = useOnlineOrderSetPickedProducts();

  const mutating = mutatingProducts;

  const pickedProducts = useProductPicker((state) => state.products);

  const continueCart = useProductPicker((state) => state.continue);
  const clearCart = useProductPicker((state) => state.clear);

  const configuration = getFragmentData(
    OnlineOrderBranchConfigurationFragment,
    data?.storefront_onlineOrderBranchConfiguration
  );

  const menu = getFragmentData(MenuFragment, configuration?.menu);
  const sections = getFragmentData(MenuSectionFragment, menu?.sections) ?? [];
  const products = getFragmentData(MenuProductFragment, menu?.menuProducts) ?? [];

  useEffect(() => {
    const pickedProducts = getFragmentData(OnlineOrderProductsFragment, props.order.onlineOrderProducts);
    continueCart(pickedProducts);
  }, [continueCart, props.order.onlineOrderProducts]);

  if (loading) return <Loading />;

  if (error) {
    return <CriticalError />;
  }

  if (!data?.storefront_onlineOrderBranchConfiguration) {
    return <UnavailableOnlineOrder website={branch.website ?? ''} />;
  }

  if (!menu) {
    return <UnavailableOnlineOrder website={branch.website ?? ''} />;
  }

  const handleUpdate = () => {
    setPickedProducts({
      variables: {
        customerLanguage: customerLanguage,
        onlineOrderID: props.order.id,
        products: pickedProducts.map((product) => ({
          productID: product.productID,
          quantity: product.quantity,
          configurations: product.configurations.map((config) => ({
            configurationID: config.configurationID,
            valueID: config.valueID,
          })),
        })),
        state: OnlineOrderUserState.FillContactInfo,
      },
    })
      .then(() => {
        clearCart();
      })
      .catch(captureException);
  };

  return (
    <>
      {/*<ProductPickerHeader />*/}

      <div className="px-4">
        <ApplicationErrorView error={error} />

        <div className="flex overflow-y-scroll">
          <div className="h-screen overflow-x-visible overflow-y-scroll">
            <RestaurantPanel branch={branch} sections={sections} />
          </div>

          <div className="grow overflow-y-scroll">
            <div className="fixed top-0 z-50 w-full md:hidden lg:hidden">
              <RestaurantPanelMobile branch={branch} sections={sections}></RestaurantPanelMobile>
            </div>
            <div className="pt-32 md:pt-0 lg:pt-0">
              <TopBanner branchID={branch.id}></TopBanner>
            </div>
            <ProductPickerRoute timezone={branch.company.settings.timezone} sections={sections} products={products} style={configuration?.categoryStyle} />
          </div>

          <div className="no-scrollbar hidden shrink-0 overflow-y-scroll lg:block">
            <CartSideBar menu={menu} onCreate={handleUpdate} disableOrderButton={mutatingProducts} />
          </div>
        </div>
      </div>

      <CartBar menu={menu} disableOrderButton={mutating} onCreate={handleUpdate} />
    </>
  );
};

export default ProductRepickerPage;

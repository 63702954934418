import {useMutation} from "@apollo/client";
import {graphql} from "../../../graphql/types";

const MUTATION = graphql(`
    mutation CreateTableBooking(
        $branchId: ID!
        $input: Storefront_TableReservationCreate!
    ) {
        storefront_createTableReservation(
            branchId: $branchId
            input: $input
        ) {
            id
            platformType
            tableId
            guestName
            status
            message
            startTime
            seats
        }
    }
`);

export default function useCreateTableBooking() {
  return useMutation(MUTATION, {});
}

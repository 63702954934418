export function naturalCompare(a: string, b: string) {
  const regex = /(\d+)|(\D+)/g;
  const aMatches = a.match(regex);
  const bMatches = b.match(regex);

  while (aMatches?.length && bMatches?.length) {
    const aMatch = aMatches.shift() ?? '';
    const bMatch = bMatches.shift() ?? '';

    if (aMatch !== bMatch) {
      const aNum = parseInt(aMatch, 10);
      const bNum = parseInt(bMatch, 10);

      if (!isNaN(aNum) && !isNaN(bNum)) {
        return aNum - bNum;
      }
      return aMatch.localeCompare(bMatch);
    }
  }

  return (aMatches?.length ?? 0) - (bMatches?.length ?? 0);
}

import { OnlineOrderItemFragment } from '@app/graphql/types/graphql.ts';
import OrderDetailPageMobile from '@app/page/online-order/order-detail/order-detail-page-mobile.tsx';
import OrderDetailPageDesktop from '@app/page/online-order/order-detail/order-detail-page-desktop.tsx';

const OrderDetailPage = (props: { onlineOrder: OnlineOrderItemFragment }) => {
  return (
    <div>
      <div className="lg:hidden">
        <OrderDetailPageMobile onlineOrder={props.onlineOrder} />
      </div>
      <div className="hidden lg:block">
        <OrderDetailPageDesktop onlineOrder={props.onlineOrder} />
      </div>
    </div>
  );
};

export default OrderDetailPage;

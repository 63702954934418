import { CheckIcon } from '@heroicons/react/20/solid';
import { ProgressStep } from '@app/components/progress.tsx';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function CleanedProgress(props: { steps: ProgressStep[] }) {
  const { steps } = props;
  return (
    <nav aria-label="Progress">
      <ol role="list" className="overflow-hidden">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className={classNames(stepIdx !== steps.length - 1 ? 'pb-10' : '', 'relative')}>
            {step.status === 'complete' ? (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div aria-hidden="true" className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-black" />
                ) : null}
                <div className="group relative flex items-start">
                  <span className="flex h-9 items-center">
                    <span
                      className={`relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-black group-hover:bg-indigo-800 ${step.color === 'red' ? 'bg-red-600' : ''}`}
                    >
                      <CheckIcon aria-hidden="true" className={`h-5 w-5 text-white`} />
                    </span>
                  </span>

                  <span className={`ml-4 flex h-9 min-w-0 flex-col items-center justify-center`}>
                    <span className={`text-sm font-medium ${step.color === 'red' ? 'text-xl text-red-600' : ''}`}>
                      {step.name}
                    </span>
                  </span>
                </div>
              </>
            ) : step.status === 'current' ? (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div aria-hidden="true" className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" />
                ) : null}
                <div aria-current="step" className="group relative flex items-start">
                  <span aria-hidden="true" className="flex h-9 items-center">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-black bg-white">
                      <span className="h-2.5 w-2.5 rounded-full bg-black" />
                    </span>
                  </span>

                  <span className="ml-4 flex h-9 min-w-0 flex-col items-center justify-center">
                    <span className="text-sm font-medium text-black">{step.name}</span>
                  </span>
                </div>
              </>
            ) : (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div aria-hidden="true" className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" />
                ) : null}
                <div className="group relative flex items-start">
                  <span aria-hidden="true" className="flex h-9 items-center">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                      <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                    </span>
                  </span>

                  <span className="ml-4 flex h-9 min-w-0 flex-col items-center justify-center">
                    <span className="text-sm font-medium text-gray-500">{step.name}</span>
                  </span>
                </div>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}

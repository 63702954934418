import {graphql} from "../../../graphql/types";
import {useQuery} from '@apollo/client';

const QUERY = graphql(`
    query Storefront_tableReservationStatus($id: ID!) {
        storefront_tableReservationStatus(id: $id) {
            ...Storefront_TableReservationItem
        }
    }
`);

export default function useGetTableReservation(id: string) {
  return useQuery(QUERY, {
    variables: {
      id: id
    },
  });
}

import { useSubscription } from "@apollo/client";
import { graphql } from '@app/graphql/types';

const QUERY = graphql(`
    subscription Storefront_tableReservationStatusUpdateSubscribe($id: ID!) {
        storefront_tableReservationStatusUpdateSubscribe(id: $id) {
            event
            tableReservation {
                id
                confirmationStatus
            }
        }
    }
`);

export default function useTableReservationUpdateSubscribe(id: string) {
  return useSubscription(QUERY, {
    variables: { id: id },
  });
}

import { graphql } from "../types";
export const Storefront_TableReservationFragment = graphql(`
    fragment Storefront_TableReservationItem on Storefront_TableReservation {
        id
        confirmationStatus
        phone
        email
        startTime
        endTime
        seats
        guestName
        message
        timezone
        flexTime
        
        branch {
            id
            name
            streetAddress
            postalCode
            addressCountry
            addressLocality
            addressRegion
            companyId
        }
    }
`);

import {
  OnlineOrderDeliveryFeeCalculationStatus,
  OnlineOrderDeliveryMode,
  OnlineOrderItemFragment,
  OnlineOrderUserState,
} from '@app/graphql/types/graphql.ts';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import ApplicationErrorView from '@app/module/error/application-error-view.tsx';
import CartSideBarPreview from '@app/page/online-order/fill-contact-info/component/cart-side-bar-preview.tsx';
import { CartSideBarPreviewMode } from '../fill-contact-info/utils/cart-side-bar-preview-mode-enum';
import { useOnlineOrderChangeUserState } from '@app/page/online-order/detail/logic/use-online-order-change-user-state.ts';
import { captureException } from '@sentry/react';
import AlertError from '@app/module/error/alert-error.tsx';
import { MapPinIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import PrimaryButton from '@app/components/primary-button.tsx';
import { ButtonSize } from '@app/components/button-size.ts';
import { useTranslate } from '@tolgee/react';

const OnlineOrderReviewPage = (props: { onlineOrder: OnlineOrderItemFragment }) => {
  const { onlineOrder } = props;

  const [changeUserState, { loading, error }] = useOnlineOrderChangeUserState();

  const backToFillAddress = () => {
    changeUserState({
      variables: {
        onlineOrderID: props.onlineOrder.id,
        state: OnlineOrderUserState.FillContactInfo,
      },
    }).catch(captureException);
  };

  const submitHandler = () => {
    changeUserState({
      variables: {
        onlineOrderID: props.onlineOrder.id,
        state: OnlineOrderUserState.Done,
      },
    }).catch(captureException);
  };

  const validateForOrderButton = () => {
    if (onlineOrder.deliveryFeeCalculationStatus === OnlineOrderDeliveryFeeCalculationStatus.OutOfRange) {
      return true;
    }
    return false;
  };
  const { t } = useTranslate();
  return (
    <div>
      <div className="pb-4">
        {onlineOrder.deliveryFeeCalculationStatus === OnlineOrderDeliveryFeeCalculationStatus.Failed && (
          <AlertError
            title={t('online-order.review-page.alert.error.title', 'Delivery problem')}
            message={t(
              'online-order.review-page.alert.error.description',
              'Do not worry, you can still order. We will contact you soon for updating.'
            )}
          />
        )}

        {onlineOrder.deliveryFeeCalculationStatus === OnlineOrderDeliveryFeeCalculationStatus.OutOfRange && (
          <AlertError
            title={t('online-order.review-page.alert.out-of-range.title', 'Delivery is too far')}
            message={t(
              'online-order.review-page.alert.out-of-range.description',
              'Please, back to previous page to edit your address.'
            )}
          />
        )}
      </div>

      <div className="mb-4 flex cursor-pointer px-4 text-gray-500 hover:underline" onClick={backToFillAddress}>
        <ChevronLeftIcon className="w-6" />
        {t('online-order.review-page.back-to-edit-address', 'Back to edit address')}
      </div>

      <ApplicationErrorView className="mx-10 my-4 p-4" error={error} />

      <div className="mx-4 grid max-w-7xl grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16 xl:mx-auto">
        <div>
          <CartSideBarPreview order={props.onlineOrder} mode={CartSideBarPreviewMode.Full} editable={false} />
        </div>

        <div className="mx-4 space-y-8">
          <p className="font-semibol4 mb-4 text-3xl">
            {t('online-order.review-page.review-side-bar.header', 'Review')}
          </p>

          <div className="my-4 space-y-2">
            <div className="flex gap-1">
              <UserCircleIcon className="h-6 w-6" />
              <p className="font-semibold">
                {t('online-order.review-page.review-side-bar.your-contact.title', 'Your contact')}
              </p>
            </div>
            <p>
              {t('online-order.review-page.review-side-bar.your-contact.name', 'Guest name')}: {onlineOrder.guestName}
            </p>
            <p>
              {t('online-order.review-page.review-side-bar.your-contact.email', 'Email')}: {onlineOrder.email}
            </p>
            <p>
              {t('online-order.review-page.review-side-bar.your-contact.phone', 'Phone')}: {onlineOrder.phone}
            </p>
            {onlineOrder.message && onlineOrder.message.length > 0 && (
              <p className="break-all">
                {t('online-order.review-page.review-side-bar.your-contact.message', 'Message')}: {onlineOrder.message}
              </p>
            )}
          </div>

          {/*Address*/}
          {onlineOrder.deliveryMode === OnlineOrderDeliveryMode.Delivery && (
            <div className="space-y-2">
              <div className="flex gap-1">
                <MapPinIcon className="h-6 w-6" />
                <p className="font-semibold">
                  {t('online-order.review-page.review-side-bar.address.title', 'Address')}
                </p>
              </div>
              <p>{onlineOrder.streetAddress}</p>
              {onlineOrder.streetAddress2 && <p>{onlineOrder.streetAddress2}</p>}
              <p>
                {onlineOrder.addressLocality}, {onlineOrder.addressRegion} {onlineOrder.postalCode}
              </p>
            </div>
          )}

          <div className="mt-6 flex items-center justify-end gap-x-6 border-t pt-4">
            <PrimaryButton
              disabled={loading || validateForOrderButton()}
              buttonSize={ButtonSize.large}
              onClick={submitHandler}
            >
              {t('online-order.review-page.review-side-bar.button.order', 'Order')}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnlineOrderReviewPage;

import { MenuProductItemFragment } from '@app/graphql/types/graphql.ts';
import { useProductPicker } from '@app/page/online-order/product-picker/logic/use-product-picker.ts';
import { getFragmentData } from '@app/graphql/types';
import {
  MenuProductConfigurationFragment,
  MenuProductConfigurationValueFragment,
} from '@app/page/online-order/model/menu-product-fragment.ts';
import PrimaryButton from '@app/components/primary-button.tsx';
import { ButtonSize } from '@app/components/button-size.ts';
import DialogButton from '@app/components/dialog-button.tsx';
import ProductConfigurationSetup from '@app/page/online-order/product-picker/component/product-configuration-setup.tsx';
import { useTranslate } from '@tolgee/react';

const ProductAddButton = (props: { product: MenuProductItemFragment }) => {
  const addToCart = useProductPicker((state) => state.add);

  const configurations = props.product.configurations.map((config) => {
    const configuration = getFragmentData(MenuProductConfigurationFragment, config);

    return {
      ...configuration,
      values: configuration.values.map((value) => {
        return getFragmentData(MenuProductConfigurationValueFragment, value);
      }),
    };
  });

  const singlePrice = configurations.length === 1 && configurations[0].values.length === 1;

  const addToCartHandler = () => {
    if (singlePrice) {
      addToCart({
        productID: props.product.id,
        quantity: 1,
        configurations: [
          {
            configurationID: configurations[0].id,
            valueID: configurations[0].values[0].id,
          },
        ],
      });
    }
  };
  const { t } = useTranslate();
  if (singlePrice) {
    return (
      <PrimaryButton
        className=""
        buttonSize={ButtonSize.medium}
        onClick={(e) => {
          addToCartHandler();
          e.stopPropagation();
        }}
      >
        {t('online-order.picker-page.product-add-button', 'Add to cart')}
      </PrimaryButton>
    );
  } else {
    return (
      <DialogButton content={<ProductConfigurationSetup product={props.product} />}>
        <PrimaryButton className="" buttonSize={ButtonSize.medium}>
          {t('online-order.picker-page.product-add-button', 'Add to cart')}
        </PrimaryButton>
      </DialogButton>
    );
  }
};

export default ProductAddButton;

import { graphql } from '@app/graphql/types';

export const OnlineOrderStateEventFragment = graphql(`
  fragment OnlineOrderStateEventItem on OnlineOrderStateEvent {
    id
    state
    timestamp
  }
`);

export const OnlineOrderProductsFragment = graphql(`
  fragment OnlineOrderProductsItem on OnlineOrderProduct {
    id
    title
    quantity

    menuProduct {
      id
      images
      code
    }

    configurations {
      id
      title
      value
      price

      menuProductConfigurationId
      menuProductConfigurationValueId
    }
  }
`);

export const OnlineOrderFragment = graphql(`
  fragment OnlineOrderItem on Storefront_OnlineOrder {
    id

    userState
    confirmationState
    deliveryMode
    state

    estimatedDuration
    deliveryFeeCalculationStatus
    streetAddress
    streetAddress2
    addressLocality
    addressRegion
    postalCode
    addressCountry

    guestName
    email
    phone
    message

    basePrice
    deliveryPrice
    pickupDiscount
    totalPrice

    createdAt
    updatedAt
    userCreatedAt

    events {
      ...OnlineOrderStateEventItem
    }

    onlineOrderProducts {
      ...OnlineOrderProductsItem
    }

    branch {
      ...BranchItem
    }
  }
`);

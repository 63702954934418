import { useQuery } from '@apollo/client';
import { graphql } from '@app/graphql/types';

const QUERY = graphql(`
  query StoreFrontBanners($companyID: String!, $type: String!) {
    storefront_banners(companyId: $companyID, type: $type) {
      bannerId
      title
      viewCount
      clickCount
      enabled
      createAt
      updateAt
      content {
        __typename
        ... on TopContent {
          type
          text
          foregroundColor
          backgroundColor
          action
        }
        ... on OverlayContent {
          type
          image
        }
      }
      timeRule {
        __typename
        ... on RangeTimeRule {
          type
          start
          end
        }
        ... on AlwaysTimeRule {
          type
        }
      }
    }
  }
`);

export default function useStoreFrontBanners(companyID: string, type: string) {
  return useQuery(QUERY, {
    variables: {
      companyID: companyID,
      type: type,
    },
  });
}

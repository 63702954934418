import { useMutation } from '@apollo/client';
import { graphql } from '@app/graphql/types';

export const QUERY = graphql(`
    mutation ChangeUserStateOfOnlineOrder($onlineOrderID: ID!, $state: OnlineOrderUserState!) {
        storefront_changeUserState(onlineOrderID: $onlineOrderID, state: $state)
    }
`);

export function useOnlineOrderChangeUserState() {
  return useMutation(QUERY, {refetchQueries: ['OnlineOrderDetailPage']});
}

import moment from 'moment-timezone';
import { Storefront_TableReservationItemFragment } from '@app/graphql/types/graphql.ts';
import { useTranslate } from '@tolgee/react';

export default function NailCustomerData({
  reservation,
}: {
  reservation: Storefront_TableReservationItemFragment | null | undefined;
}) {
  const { t } = useTranslate();
  if (!reservation) {
    return <div />;
  }

  return (
    <div className="flex max-w-[450px] flex-col gap-4 border-gray-900/10">
      <div className="sm:col-span-3">
        <label htmlFor="seats" className="block text-sm font-medium leading-6 text-gray-900">
          {t('reservation.detail.customer-data.name.label', 'Customer Name')}
        </label>
        <div className="mt-2">
          <p className="block w-full break-words rounded-md p-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            {reservation.guestName}
          </p>
        </div>
      </div>

      <div className="sm:col-span-3">
        <label htmlFor="date" className="block text-sm font-medium leading-6 text-gray-900">
          {t('reservation.detail.customer-data.date.label', 'Date')}
        </label>
        <div className="mt-2">
          <p className="block w-full rounded-md p-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            {moment(reservation.startTime).tz(reservation.timezone).format('DD/MM/YYYY')}
          </p>
        </div>
      </div>

      <div className="sm:col-span-3">
        <label htmlFor="time" className="block text-sm font-medium leading-6 text-gray-900">
          {t('reservation.detail.customer-data.time.label', 'Time')}
        </label>
        <div className="mt-2">
          <p className="block w-full rounded-md p-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            {moment(reservation.startTime).tz(reservation.timezone).format('HH:mm')}
          </p>
        </div>
      </div>

      {reservation.flexTime && (
        <div className="sm:col-span-3">
          <label htmlFor="time" className="block text-sm font-medium leading-6 text-gray-900">
            {t('reservation.detail.customer-data.flex-duration.label', 'The reservation time can be moved up to')}
          </label>
          <div className="mt-2">
            <p className="block w-full rounded-md p-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
              {reservation.flexTime} {t('reservation.detail.customer-data.flex-duration.minutes', 'minutes')}
            </p>
          </div>
        </div>
      )}

      <div className="sm:col-span-3">
        <label htmlFor="at" className="block text-sm font-medium leading-6 text-gray-900">
          {t('reservation.detail.customer-data.location.label', 'Location')}
        </label>
        <div className="mt-2">
          <p className="block w-full break-words rounded-md p-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            {reservation.branch.name}
          </p>
        </div>
      </div>

      <div className="sm:col-span-3">
        <label htmlFor="at" className="block text-sm font-medium leading-6 text-gray-900">
          {t('reservation.detail.customer-data.address.label', 'Address')}
        </label>
        <div className="mt-2">
          <p className="block w-full break-words rounded-md p-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            {`${reservation.branch.streetAddress}, ${reservation.branch.postalCode}, ${reservation.branch.addressLocality}`}
          </p>
        </div>
      </div>

      <div className="sm:col-span-3">
        <label htmlFor="seats" className="block text-sm font-medium leading-6 text-gray-900">
          {t('reservation.detail.customer-data.seats.label', 'Number of seats')}
        </label>
        <div className="mt-2">
          <p className="block w-full rounded-md p-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            {reservation.seats}
          </p>
        </div>
      </div>

      {reservation.message.length > 0 && (
        <div className="sm:col-span-3">
          <label htmlFor="message" className="block text-sm font-medium leading-6 text-gray-900">
            {t('reservation.detail.customer-data.message.label', 'Message')}
          </label>
          <div className="mt-2">
            <textarea className="block w-full min-h-36 rounded-md p-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
              {reservation.message}
            </textarea>
          </div>
        </div>
      )}
    </div>
  );
}

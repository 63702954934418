import { createBrowserRouter } from 'react-router-dom';
import MenuPreviewPage from './page/menu-preview/menu-preview-page.tsx';
import TableReservationDetail from '@app/page/table-reservation-detail/table-reservation-detail.tsx';
import TableReservationCreate from '@app/page/table-reservation/table-reservation-create.tsx';
import { OnlineOrderRouter } from '@app/page/online-order/online-order.router.tsx';
import NailTableReservationCreate from '@app/page/table-reservation/nail-table-reservation-create.tsx';
import NailTableReservationDetail from './page/table-reservation-detail/nail-table-reservation-detail.tsx';

const globalRouter = createBrowserRouter([
  {
    path: '/',
    element: <div>Hello world!</div>,
  },
  {
    path: '/menu-preview',
    element: <MenuPreviewPage />,
  },
  {
    path: 'table-reservation',
    children: [
      {
        path: '',
        children: [
          {
            path: '',
            element: <TableReservationCreate />,
          },
          {
            path: 'track',
            element: <TableReservationDetail />,
          },
        ]
      },
      {
        path: 'nail',
        children: [
          {
            path: '',
            element: <NailTableReservationCreate />,
          },
          {
            path: 'track',
            element: <NailTableReservationDetail />,
          },
        ]
      }
      
    ],
  },
  OnlineOrderRouter,
]);

export default globalRouter;

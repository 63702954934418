import { useState, useEffect } from 'react';
import useStoreFrontBranch from '@app/page/online-order/banner/use-storefront_branch-query.ts';
import useStoreFrontBanners from '@app/page/online-order/banner/use-storefront_banners-query.ts';
import { XMarkIcon } from '@heroicons/react/24/outline';

export default function TopBanner(props: { branchID: string | null }) {
  const branchID = props.branchID;

  // State to manage banner visibility
  const [isVisible, setIsVisible] = useState(true);

  // Fetch branch data
  const { data: branchData, error: branchError, loading: branchLoading } = useStoreFrontBranch(branchID ?? '');
  const companyId = branchData?.storefront_branch?.companyId;

  // Fetch banners data
  const {
    data: topBannersData,
    error: bannersError,
    loading: bannersLoading,
  } = useStoreFrontBanners(companyId ?? '', 'TOP');

  // Handle loading and error states
  useEffect(() => {
    if (branchLoading || bannersLoading) return;
    if (branchError ?? bannersError) return;
  }, [branchLoading, bannersLoading, branchError, bannersError]);

  if (!isVisible || branchLoading || bannersLoading) return null;

  if (branchError) return <div>Error loading branch data: {branchError.message}</div>;
  if (bannersError) return <div>Error loading banners data: {bannersError.message}</div>;

  const topBanner = topBannersData?.storefront_banners[0];
  if (topBanner?.content.__typename !== 'TopContent') {
    return null;
  }

  const backgroundColor = topBanner.content.backgroundColor ?? '#000000';
  const textColor = topBanner.content.foregroundColor ?? '#FFFFFF';

  // Function to hide the banner
  const hideBanner = () => {
    setIsVisible(false);
  };

  return (
    <div
      className="rounded-xs relative flex items-center justify-center gap-x-6 px-6 py-2.5 sm:px-3.5"
      style={{ backgroundColor: backgroundColor }}
    >
      <p className="text-sm leading-6" style={{ color: textColor }}>
        <a href={topBanner.content.action ?? '#'}>
          <span aria-hidden="true" className="flex flex-row justify-center">
            <span className="text-center font-semibold tracking-wide">{topBanner.content.text}</span>
          </span>
        </a>
        <button
          type="button"
          className="absolute right-1 top-2 z-10 inline-flex justify-center rounded-md bg-gray-500 px-1 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-900"
          onClick={hideBanner}
        >
          <XMarkIcon aria-hidden="true" className="h-5 w-5 text-white" />
        </button>
      </p>
    </div>
  );
}

import { MenuProductLabel } from '@app/graphql/types/graphql.ts';
import { ReactSVG } from 'react-svg';

const ProductLabelIcon = (props: { label: MenuProductLabel }) => {
  switch (props.label) {
    case MenuProductLabel.Alcohol: {
      return (
        <ReactSVG
          src="https://inhouse-storage.fra1.cdn.digitaloceanspaces.com/static/alcohol.svg"
          beforeInjection={(svg) => {
            svg.querySelector('path')?.setAttribute('fill', '#ffcc00');
            svg.classList.add('w-5', 'h-5');
          }}
        />
      );
    }
    case MenuProductLabel.Spicy: {
      return (
        <ReactSVG
          src="https://inhouse-storage.fra1.cdn.digitaloceanspaces.com/static/spicy.svg"
          beforeInjection={(svg) => {
            svg.classList.add('w-5', 'h-5');
          }}
        />
      );
    }
    case MenuProductLabel.Vegan: {
      return (
        <ReactSVG
          src="https://inhouse-storage.fra1.cdn.digitaloceanspaces.com/static/vegan.svg"
          beforeInjection={(svg) => {
            svg.querySelector('path')?.setAttribute('fill', '#00b359');
            svg.classList.add('w-5', 'h-5');
            svg.querySelector('path')?.setAttribute('stroke-width', '2');
          }}
        />
      );
    }
    case MenuProductLabel.Hot: {
      return (
        <ReactSVG
          src="https://inhouse-storage.fra1.cdn.digitaloceanspaces.com/static/hot.svg"
          beforeInjection={(svg) => {
            svg.querySelector('path')?.setAttribute('fill', '#ff1a1a');
            svg.classList.add('w-5', 'h-5');
          }}
        />
      );
    }
    case MenuProductLabel.ExtraHot: {
      return (
        <ReactSVG
          src="https://inhouse-storage.fra1.cdn.digitaloceanspaces.com/static/extra-hot.svg"
          beforeInjection={(svg) => {
            svg.querySelector('path')?.setAttribute('fill', '#990000');
            svg.classList.add('w-5', 'h-5');
          }}
        />
      );
    }
    case MenuProductLabel.Vegetarian: {
      return (
        <ReactSVG
          src="https://inhouse-storage.fra1.cdn.digitaloceanspaces.com/static/vegetarian.svg"
          beforeInjection={(svg) => {
            svg.querySelector('path')?.setAttribute('fill', '#003380');
            svg.classList.add('w-5', 'h-5');
          }}
        />
      );
    }
  }
};

export default ProductLabelIcon;

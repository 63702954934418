import { graphql } from '@app/graphql/types';

export const OnlineOrderBranchConfigurationFragment = graphql(`
    fragment OnlineOrderBranchConfigurationItem on Storefront_OnlineOrderBranchConfiguration {
        branchID
        menu {
            ...MenuItem
        }
        categoryStyle
        pickupDiscountAmount
        pickupDiscountType
        pickupEnable
        deliveryEnable
        minimumOrderAmountForDelivery
    }
`);

import classNames from 'classnames';

export default function AlertBanner(props: { className?: string; description: string }) {
  return (
    <div
      className={classNames(
        'inset-x-0 flex items-center gap-x-6 bg-red-600 px-6 py-2.5 sm:px-3.5 sm:before:flex-1',
        props.className
      )}
    >
      <p className="text-sm leading-6 text-white">
        <a href="#">
          <strong className="font-semibold">Alert</strong>
          <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
            <circle cx={1} cy={1} r={1} />
          </svg>
          {props.description}
        </a>
      </p>
      <div className="flex flex-1 justify-end"></div>
    </div>
  );
}

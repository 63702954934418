import { formatCurrency } from '@app/components/price/currency-formatter';
import { PickupDiscountType } from '@app/graphql/types/graphql';

export function pickupDiscountAdditionalText(
  pickupDiscountAmount: number,
  type: PickupDiscountType,
  currency: string
): string {
  switch (type) {
    case PickupDiscountType.None:
      return 'No pickup discount';
    case PickupDiscountType.Percentage:
      return `Discount for pickup online order: ${pickupDiscountAmount.toString()}` + '%';
    case PickupDiscountType.Amount:
      return `Discount for pickup online order: ${formatCurrency(pickupDiscountAmount, currency)} `;
  }
}

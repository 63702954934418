import { ProgressStep } from '@app/components/progress.tsx';
import { OnlineOrderDeliveryMode, OnlineOrderItemFragment, OnlineOrderState } from '@app/graphql/types/graphql.ts';
import CartSideBarPreview from '@app/page/online-order/fill-contact-info/component/cart-side-bar-preview.tsx';
import { CartSideBarPreviewMode } from '../fill-contact-info/utils/cart-side-bar-preview-mode-enum';
import { MapPinIcon, ShoppingCartIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import PrimaryButton from '@app/components/primary-button.tsx';
import SecondaryButton from '@app/components/secondary-button.tsx';
import { useNavigate } from 'react-router-dom';
import { BranchFragment } from '@app/page/online-order/model/branch-fragment.ts';
import { getFragmentData } from '@app/graphql/types';
import moment from 'moment-timezone';
import HorizontalProgress from '@app/components/horizontal-progress.tsx';
import { useTranslate } from '@tolgee/react';

const OrderDetailPageDesktop = (props: { onlineOrder: OnlineOrderItemFragment }) => {
  const { onlineOrder } = props;

  const branch = getFragmentData(BranchFragment, onlineOrder.branch);

  const steps = useOrderSteps(onlineOrder);
  const navigate = useNavigate();
  const { t } = useTranslate();
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="mt-10 w-1/2">
        <p className="font-semibol4 mb-4 text-3xl">{t('online-order.detail-page.header', 'Your order')}</p>
      </div>
      <div className="mt-5 w-1/2 border-black">
        <HorizontalProgress steps={steps} />
      </div>
      {onlineOrder.estimatedDuration && onlineOrder.state === OnlineOrderState.InProgress && (
        <div className="mt-5 flex w-1/2 items-center justify-center gap-2">
          <ShoppingCartIcon className="h-6 w-6" />
          <p className="text-2xl">
            <span className="font-semibold">
              {t('online-order.review-page.review-side-bar.button.estimated-time', 'Estimated time')}:
            </span>
            <span className="text-gray-600">
              {' '}
              {moment().add(onlineOrder.estimatedDuration, 'minutes').format('HH:mm')}{' '}
              {t('online-order.review-page.review-side-bar.button.minutes', 'minutes')}
            </span>
          </p>
        </div>
      )}
      <div className="mx-4 mt-5 w-1/2 max-w-7xl">
        <CartSideBarPreview order={props.onlineOrder} mode={CartSideBarPreviewMode.Full} editable={false} />
      </div>

      <div className="mx-4 mt-5 w-1/2 gap-10 xl:mx-auto">
        <div className="mt-10 grid w-full grid-cols-2 p-1">
          <div className="mr-5 space-y-2">
            <div className="flex gap-1">
              <UserCircleIcon className="h-6 w-6" />
              <p className="font-semibold">{t('online-order.detail-page.your-contact.title', 'Your contact')}</p>
            </div>
            <p>
              {t('online-order.detail-page.your-contact.name', 'Guest name')}: {onlineOrder.guestName}
            </p>
            <p>
              {t('online-order.detail-page.your-contact.email', 'Email')}: {onlineOrder.email}
            </p>
            <p>
              {t('online-order.detail-page.your-contact.phone', 'Phone')}: {onlineOrder.phone}
            </p>
            {onlineOrder.message && onlineOrder.message.length > 0 && (
              <p className="reak-words max-w-72 break-all">
                {t('online-order.detail-page.your-contact.message', 'Message')}: {onlineOrder.message}
              </p>
            )}
            <p>
              {t('online-order.detail-page.your-contact.date', 'Date')}:{' '}
              {moment(onlineOrder.userCreatedAt).format('DD/MM/YYYY HH:mm')}
            </p>
          </div>

          <div className="ml-5">
            {/*Address*/}
            {onlineOrder.deliveryMode === OnlineOrderDeliveryMode.Delivery && (
              <div className="space-y-2">
                <div className="flex gap-1">
                  <MapPinIcon className="h-6 w-6" />
                  <p className="font-semibold">{t('online-order.detail-page.address.title', 'Address')}</p>
                </div>
                <p>{onlineOrder.streetAddress}</p>
                {onlineOrder.streetAddress2 && <p>{onlineOrder.streetAddress2}</p>}
                <p>
                  {onlineOrder.addressLocality}, {onlineOrder.addressRegion} {onlineOrder.postalCode}
                </p>
              </div>
            )}
          </div>

          <div className="relative col-span-2 mt-6 items-center justify-between gap-x-6 pb-4 pt-4">
            {branch.website && (
              <a href={branch.website} target="_blank" rel="noreferrer">
                <SecondaryButton>
                  {t('online-order.detail-page.button.back-to-website', 'Back to website')}
                </SecondaryButton>
              </a>
            )}
            <div className="absolute right-2 pb-10">
              <PrimaryButton
                onClick={() => {
                  navigate(`/online-order/start?branchID=${branch.id}`);
                }}
              >
                {t('online-order.detail-page.button.new-order', 'New order')}
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function useOrderSteps(onlineOrder: OnlineOrderItemFragment): ProgressStep[] {
  let result: ProgressStep[] = [];

  switch (onlineOrder.state) {
    case OnlineOrderState.Done:
      result = [
        {
          id: 'waiting-of-confirmation',
          name: 'Waiting of confirmation',
          status: 'complete',
          description: '',
        },
        {
          id: 'in-progress',
          name: 'In Progress',
          status: 'complete',
          description: '',
        },
        {
          id: 'done',
          name: 'Finished',
          status: 'complete',
          description: '',
        },
      ];
      break;
    case OnlineOrderState.WaitingForConfirmation:
      result = [
        {
          id: 'waiting-of-confirmation',
          name: 'Waiting of confirmation',
          status: 'current',
          description: '',
        },
        {
          id: 'in-progress',
          name: 'In Progress',
          status: 'upcoming',
          description: '',
        },
        {
          id: 'done',
          name: 'Finished',
          status: 'upcoming',
          description: '',
        },
      ];
      break;
    case OnlineOrderState.InProgress:
      result = [
        {
          id: 'waiting-of-confirmation',
          name: 'Waiting of confirmation',
          status: 'complete',
          description: '',
        },
        {
          id: 'in-progress',
          name: 'In Progress',
          status: 'current',
          description: '',
        },
        {
          id: 'done',
          name: 'Finished',
          status: 'upcoming',
          description: '',
        },
      ];
      break;
    case OnlineOrderState.Canceled:
      result = [
        {
          id: 'waiting-of-confirmation',
          name: 'Waiting of confirmation',
          status: 'upcoming',
          description: '',
        },
        {
          id: 'in-progress',
          name: 'In Progress',
          status: 'upcoming',
          description: '',
        },

        {
          id: 'cancel',
          name: 'Cancel',
          status: 'complete',
          description: '',
          color: 'red',
        },
      ];
  }
  return result;
}

export default OrderDetailPageDesktop;

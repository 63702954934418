import { PickedProduct } from '@app/page/online-order/product-picker/logic/use-product-picker.ts';
import { MenuItemFragment } from '@app/graphql/types/graphql.ts';
import { getFragmentData } from '@app/graphql/types';
import {
  MenuProductConfigurationFragment,
  MenuProductConfigurationValueFragment,
  MenuProductFragment,
} from '@app/page/online-order/model/menu-product-fragment.ts';

export function calculateTotal(pickedProducts: PickedProduct[], menu: MenuItemFragment) {
  return pickedProducts
    .map((pickedProduct) => {
      const product = menu.menuProducts
        .map((e) => getFragmentData(MenuProductFragment, e))
        .find((product) => {
          return product.id === pickedProduct.productID;
        });

      if (!product) {
        return 0;
      }

      const configurations = product.configurations.map((e) => getFragmentData(MenuProductConfigurationFragment, e));
      const values = configurations.map((c) => getFragmentData(MenuProductConfigurationValueFragment, c.values)).flat();

      const pickedValues = pickedProduct.configurations
        .map((c) => values.find((v) => v.id === c.valueID))
        .map((v) => v?.price ?? 0);

      const price = pickedValues.flat().reduce((a, b) => a + b, 0);
      return price * pickedProduct.quantity;
    })
    .reduce((a, b) => a + b, 0);
}

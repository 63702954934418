import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyC-h59jYQOURCanu5QCJpiy-3FUP16pSDc",
  authDomain: "inhouse-55263.firebaseapp.com",
  projectId: "inhouse-55263",
  storageBucket: "inhouse-55263.appspot.com",
  messagingSenderId: "838641558620",
  appId: "1:838641558620:web:8796dec55990145096b299",
  measurementId: "G-XQPXT00R2G",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { analytics };

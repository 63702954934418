import { ProgressStep } from '@app/components/progress.tsx';
import { OnlineOrderDeliveryMode, OnlineOrderItemFragment, OnlineOrderState } from '@app/graphql/types/graphql.ts';
import CleanedProgress from '@app/components/cleaned-progress.tsx';
import CartSideBarPreview from '@app/page/online-order/fill-contact-info/component/cart-side-bar-preview.tsx';
import { CartSideBarPreviewMode } from '../fill-contact-info/utils/cart-side-bar-preview-mode-enum';
import { MapPinIcon, ShoppingCartIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import PrimaryButton from '@app/components/primary-button.tsx';
import SecondaryButton from '@app/components/secondary-button.tsx';
import { useNavigate } from 'react-router-dom';
import { BranchFragment } from '@app/page/online-order/model/branch-fragment.ts';
import { getFragmentData } from '@app/graphql/types';
import moment from 'moment-timezone';

const OrderDetailPageMobile = (props: { onlineOrder: OnlineOrderItemFragment }) => {
  const { onlineOrder } = props;

  const branch = getFragmentData(BranchFragment, onlineOrder.branch);

  const steps = useOrderSteps(onlineOrder);
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="mt-5">
        <p className="font-semibol4 mb-4 text-3xl">Your Order</p>
      </div>
      <div className="mx-4 mt-2 max-w-7xl md:w-1/2">
        <CartSideBarPreview order={props.onlineOrder} mode={CartSideBarPreviewMode.Full} editable={false} />
      </div>

      <div className="border-10 mx-4 mt-5 grid max-w-7xl grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-10 xl:mx-auto">
        <div className="ml-1 mr-10 flex w-full justify-center lg:border-r-2">
          <CleanedProgress steps={steps}></CleanedProgress>
        </div>
        <hr />
        <div className="mx-4 w-full space-y-8">
          {onlineOrder.estimatedDuration && onlineOrder.state === OnlineOrderState.InProgress && (
            <div className="space-y-2">
              <div className="flex gap-1">
                <ShoppingCartIcon className="h-6 w-6" />
                <p className="font-semibold">Time</p>
              </div>

              {onlineOrder.estimatedDuration && <p>Estimated delivery time: {onlineOrder.estimatedDuration} minute</p>}
            </div>
          )}

          <div className="my-4 space-y-2">
            <div className="flex gap-1">
              <UserCircleIcon className="h-6 w-6" />
              <p className="font-semibold">Your contact</p>
            </div>
            <p>Guest name: {onlineOrder.guestName}</p>
            <p>Email: {onlineOrder.email}</p>
            <p>Phone: {onlineOrder.phone}</p>
            {onlineOrder.message && onlineOrder.message.length > 0 && (
              <p className="break-all">Message: {onlineOrder.message}</p>
            )}
            <p>Date: {moment(onlineOrder.userCreatedAt).format('DD/MM/YYYY HH:mm')}</p>
          </div>

          {/*Address*/}
          {onlineOrder.deliveryMode === OnlineOrderDeliveryMode.Delivery && (
            <div className="space-y-2">
              <div className="flex gap-1">
                <MapPinIcon className="h-6 w-6" />
                <p className="font-semibold">Address</p>
              </div>
              <p>{onlineOrder.streetAddress}</p>
              {onlineOrder.streetAddress2 && <p>{onlineOrder.streetAddress2}</p>}
              <p>
                {onlineOrder.addressLocality}, {onlineOrder.addressRegion} {onlineOrder.postalCode}
              </p>
            </div>
          )}

          <div className="mt-6 flex items-center justify-between gap-x-6 border-t pb-4 pt-4">
            {branch.website && (
              <a href={branch.website} target="_blank" rel="noreferrer">
                <SecondaryButton>Back to Website</SecondaryButton>
              </a>
            )}
            <div className="pb-5">
              <PrimaryButton
                onClick={() => {
                  navigate(`/online-order/start?branchID=${branch.id}`);
                }}
              >
                New Order
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function useOrderSteps(onlineOrder: OnlineOrderItemFragment): ProgressStep[] {
  let result: ProgressStep[] = [];

  switch (onlineOrder.state) {
    case OnlineOrderState.Done:
      result = [
        {
          id: 'waiting-of-confirmation',
          name: 'Waiting of confirmation',
          status: 'complete',
          description: '',
        },
        {
          id: 'in-progress',
          name: 'In Progress',
          status: 'complete',
          description: '',
        },
        {
          id: 'done',
          name: 'Finished',
          status: 'complete',
          description: '',
        },
      ];
      break;
    case OnlineOrderState.WaitingForConfirmation:
      result = [
        {
          id: 'waiting-of-confirmation',
          name: 'Waiting of confirmation',
          status: 'current',
          description: '',
        },
        {
          id: 'in-progress',
          name: 'In Progress',
          status: 'upcoming',
          description: '',
        },
        {
          id: 'done',
          name: 'Finished',
          status: 'upcoming',
          description: '',
        },
      ];
      break;
    case OnlineOrderState.InProgress:
      result = [
        {
          id: 'waiting-of-confirmation',
          name: 'Waiting of confirmation',
          status: 'complete',
          description: '',
        },
        {
          id: 'in-progress',
          name: 'In Progress',
          status: 'current',
          description: '',
        },
        {
          id: 'done',
          name: 'Finished',
          status: 'upcoming',
          description: '',
        },
      ];
      break;
    case OnlineOrderState.Canceled:
      result = [
        {
          id: 'waiting-of-confirmation',
          name: 'Waiting of confirmation',
          status: 'upcoming',
          description: '',
        },
        {
          id: 'in-progress',
          name: 'In Progress',
          status: 'upcoming',
          description: '',
        },

        {
          id: 'cancel',
          name: 'Cancel',
          status: 'complete',
          description: '',
          color: 'red',
        },
      ];
  }
  return result;
}

export default OrderDetailPageMobile;

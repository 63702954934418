import { useMenuPreview, useCompany } from './use-menu-query.ts';
import { useSearchParams } from 'react-router-dom';
import MenuPreviewPdf from './component/menu-preview-pdf.tsx';
import CDNLink from '../../utils/cdn-link.ts';
import { useRef, useState } from 'react';
import FoodCard from './component/food-card.tsx';
import useWindowSize from './component/hook.tsx';
import FoodCardMobile from './component/food-card-mobile.tsx';
import { WebMenuPreviewType } from '@app/graphql/types/graphql.ts';

const MenuPreviewPage = () => {
  const [params] = useSearchParams();

  //take menu
  const companyId = params.get('companyID');
  const { data, loading } = useMenuPreview(companyId);

  //take company data (currency)
  const { data: companyData } = useCompany(companyId);
  const currency = companyData?.companyCurrency;
  console.log('currency', currency, typeof currency);

  //section for click to scroll
  const sectionRefs = useRef<Record<string, HTMLElement | null>>({});
  const [sectionCheck, setSectionCheck] = useState('Sushi');
  const handleSection = (x: string) => {
    setSectionCheck(x);
    sectionRefs.current[x]?.scrollIntoView({ behavior: 'smooth' });
  };

  //hook for check screen size
  const { width } = useWindowSize();
  const isSmallScreen = width <= 768;

  // host to take image
  const host: string = import.meta.env.VITE_CDN_HOST as string;

  if (companyId === null) {
    return <div>Company ID is required</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return <div>No data</div>;
  }

  if (data.menuPreview.type === WebMenuPreviewType.File) {
    if (!data.menuPreview.url) {
      return <div>Menu preview is not setup</div>;
    } else {
      return (
        <div className="relative flex flex-col items-center justify-center overflow-auto bg-gray-900 p-5 text-white">
          <div className="fixed left-0 top-0 z-30 rounded-lg p-3 shadow-md">
            <button className="rounded bg-gray-300 px-4 py-2 font-bold text-gray-800 shadow-md hover:bg-gray-400">
              Back
            </button>
          </div>
          <div className="overflow-hidden pt-16">
            <MenuPreviewPdf
              className="mt-5 w-full sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/3"
              pdfURL={CDNLink(data.menuPreview.url)}
            />
          </div>
        </div>
      );
    }
  }

  return (
    <div className="flex flex-col justify-center">
      {/* header */}
      <div className="mb-8 bg-blue-700 text-center text-white">
        <h1 className="mb-2 text-3xl font-normal">{data.menuPreview.menu?.title}</h1>
        <h3 className="text-xl font-semibold">&mdash; MENU &mdash; </h3>
      </div>

      {/* section list */}
      <div className="sticky top-3 z-10 m-auto inline-block h-full justify-center rounded-full bg-gray-200 p-1">
        <div className="flex flex-row">
          {data.menuPreview.menu?.sections.map((section, index) => (
            <button
              key={index}
              className={
                sectionCheck === section.name
                  ? 'tab relative block h-10 rounded-full bg-gray-300 px-8'
                  : 'tab relative block h-10 rounded-full px-8'
              }
              onClick={() => {
                handleSection(section.name);
              }}
            >
              <span className="text-gray-800">{section.name}</span>
            </button>
          ))}
        </div>
      </div>
      {/* card of food list */}
      {isSmallScreen ? (
        // small screen
        <div className="">
          <div>
            {data.menuPreview.menu?.sections.map((section, index) => (
              <div key={index} className="mt-10">
                <div className="m-2 pb-5 font-mono text-lg" ref={(el) => (sectionRefs.current[section.name] = el)}>
                  <span>{section.name}</span>
                </div>

                <div className="flex flex-col">
                  {data.menuPreview.menu?.menuProducts.map((product, index) => {
                    if (section.name === product.section?.name) {
                      return (
                        <div key={product.id}>
                          <div className="ml-2 mr-4 mt-2" key={index}>
                            <FoodCardMobile
                              title={product.title}
                              description={product.description}
                              image={`${host}/${String(product.images[0])}`}
                              foodType={product.section.name}
                              configurations={product.configurations}
                              currency={currency ?? 'USD'}
                            ></FoodCardMobile>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        // large screen
        <div className="">
          <div className="">
            {data.menuPreview.menu?.sections.map((section, index) => (
              <div key={index} className="mt-10">
                <div className="m-2 pb-5 font-mono text-lg" ref={(el) => (sectionRefs.current[section.name] = el)}>
                  <span className="mr-2 rounded bg-blue-200 px-2.5 py-0.5 font-mono text-lg text-blue-800">
                    {section.name}
                  </span>
                </div>

                <div className="flex flex-wrap justify-center">
                  {data.menuPreview.menu?.menuProducts.map((product, index) => {
                    if (section.name === product.section?.name) {
                      return (
                        <div key={product.id} className="p-2">
                          <div className="ml-2 mr-2 mt-2" key={index}>
                            <FoodCard
                              title={product.title}
                              description={product.description}
                              image={`${host}/${String(product.images[0])}`}
                              foodType={product.section.name}
                              configurations={product.configurations}
                              currency={currency ?? 'USD'}
                            ></FoodCard>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuPreviewPage;

import { useSearchParams } from "react-router-dom";
import ReservationProcess from "./component/reservation-process.tsx";
import { useTableReservation } from "./logic/use-table-reservation.ts";
import { useEffect } from "react";

export default function TableReservationDetail() {
  const [searchParams] = useSearchParams();
  const tableReservationId = searchParams.get("tableReservationId") ?? "";
  const branchID = searchParams.get("branchID");

  const { data, reservation } = useTableReservation(tableReservationId);

  useEffect(() => {
    document.title = "Table Reservation Detail";
  }, []);

  return (
    <>
      <div className="w-full h-screen flex flex-col justify-center items-center ">
        {<ReservationProcess branchID={branchID} reservation={reservation} data={data} />}
      </div>
    </>
  );
}

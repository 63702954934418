import Center from '@app/components/center.tsx';
import Spinner from '@app/components/spinner.tsx';

export default function Loading() {
  return (
    <Center className="py-4">
      <Spinner />
    </Center>
  );
}

import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { ReactNode, useState } from 'react';

const DialogButton = (props: { children?: ReactNode; content?: ReactNode }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div
        onClick={(e) => {
          if (props.content) {
            e.stopPropagation();
            setOpen(true);
          }
        }}
      >
        {props.children}
      </div>
      <Dialog
        open={open}
        className="relative z-10 focus:outline-none"
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogBackdrop transition={true} className="fixed inset-0 bg-black/30" />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <DialogPanel
              transition
              className="data-[closed]:transform-[scale(95%)] w-full max-w-md rounded-xl border bg-white p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:opacity-0"
            >
              {props.content}
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default DialogButton;

import React from 'react';
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { useTranslate } from '@tolgee/react';

interface DurationInputProps {
  items: number[];
  value: number | null;
  onChange: (value: number | null) => void;
}

const DurationInput: React.FC<DurationInputProps> = ({ items, value, onChange }) => {
  const fullItems = items.concat([0]).sort();
  const { t } = useTranslate();

  return (
    <Listbox value={value} onChange={onChange}>
      <>
        <Label className="flex flex-col text-sm font-medium leading-6 text-gray-900">
          <span>{t('reservation.create-form.form-input.flex-duration.label', 'The time can be moved up to:')}</span>
          <span className="font-normal italic text-gray-400">
            {t(
              'reservation.create-form.form-input.flex-duration.description',
              'Alternative time range in case restaurant is full.'
            )}
          </span>
        </Label>
        <div className="relative mt-2">
          <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <span className="block truncate">
              {value === null
                ? t('reservation.create-form.form-input.flex-duration.select-duration', 'Select duration')
                : value === 0
                  ? t('reservation.create-form.form-input.flex-duration.no-alternative', 'No alternative')
                  : `${value.toString()} ` + t('reservation.create-form.form-input.flex-duration.minutes', 'minutes')}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </ListboxButton>

          <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {fullItems.map((item) => (
              <ListboxOption
                key={item}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-3 pr-9 ${
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  }`
                }
                value={item}
              >
                {({ selected, active }) => (
                  <>
                    <span className={`block truncate ${selected ? 'font-semibold' : 'font-normal'}`}>
                      {item == 0
                        ? t('reservation.create-form.form-input.flex-duration.no-alternative', 'No alternative')
                        : `${item.toString()} ` +
                          t('reservation.create-form.form-input.flex-duration.minutes', 'minutes')}
                    </span>
                    {selected && (
                      <span
                        className={`absolute inset-y-0 right-0 flex items-center pr-4 ${
                          active ? 'text-white' : 'text-indigo-600'
                        }`}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </div>
      </>
    </Listbox>
  );
};

export default DurationInput;
